@font-face {
  font-family: "United Sans Light";
  src: url("UnitedSansReg-Light.woff") format("woff")
}

@font-face {
  font-family: "United Sans Medium";
  src: url("UnitedSansReg-Medium.woff") format("woff")
}

@font-face {
  font-family: "United Sans Bold";
  src: url("UnitedSansReg-Bold.woff") format("woff")
}

$h1-extra-large-font-sizes: (
        null: 45px,
        small: 45px,
        medium: 55px,
        large: 70px,
        xlarge : 84px,
);

$h1-large-font-sizes: (
        null: 44px,
        small: 44px,
        medium: 44px,
        large: 48px,
        xlarge : 54px,
);

$h1-font-sizes: (
        null: 36px,
        small: 36px,
        medium: 40px,
        large: 46px,
        xlarge : 54px,
);

$h2-font-sizes: (
        null: 36px,
        small: 36px,
        medium: 36px,
        large: 40px,
        xlarge : 42px,
);

$h3-font-sizes: (
        null : 26px,
        small : 26px,
        medium : 26px,
        large: 30px,
        xlarge : 32px,
);

$h4-font-sizes: (
        null : 20px,
        small : 20px,
        medium : 20px,
        large: 22px,
        xlarge : 24px,
);

$h5-font-sizes: (
        null : 20px,
        small : 20px,
        medium : 20px,
        large: 22px,
        xlarge : 24px,
);

$h6-font-sizes: (
        null : 14px,
        small : 14px,
        medium : 14px,
        large: 14px,
        xlarge : 16px,
);

$ingress-font-sizes: (
        null : (16px, 24px),
        small : (16px, 24px),
        medium : (18px, 26px),
        large: (20px, 28px),
        xlarge : (24px, 34px),
);

$blockquote-font-sizes: (
        null : (16px, 24px),
        small : (16px, 24px),
        medium : (18px, 26px),
        large: (24px, 28px),
        xlarge : (32px, 40px),
);

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  color: $headings-color;
  margin-bottom: $margin;
  font-style: normal;
  font-family: $headings-font-family;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
}

h3, h4, h5, h6 {
  font-family: $font-family-sans-serif;
  text-transform: none;
  letter-spacing: 0;
}

h1, .h1 {
  @include font-size($h1-font-sizes);
}

h2, .h2 {
  @include font-size($h2-font-sizes);
}

h3, .h3 {
  @include font-size($h3-font-sizes);
}

h4, .h4 {
  @include font-size($h4-font-sizes);
}

h5, .h5 {
  @include font-size($h5-font-sizes);
  font-family: $font-family-sans-serif;
  font-weight: 500;
  text-transform: none;
}

h6, .h6 {
  @include font-size($h6-font-sizes);
}

.heading-extra-large {
  @include font-size($h1-extra-large-font-sizes);
}

.heading-mini {
  font-size: $font-size-extra-small;
}

body {
  font-weight: $font-weight-base;
}

a {
  font-weight: $font-weight-medium;
  text-decoration: none;
}

hr {
  border-color: $gray;
  margin-top: $margin;
  margin-bottom: $margin;
}

p {
  margin-bottom: $margin;
  line-height: $line-height-normal;
}

strong {
  font-weight: $font-weight-semibold;
}


blockquote {
  padding: $margin;
  margin: 0 0 $margin 0;
  border: none;
  p {
    @include font-size($blockquote-font-sizes);
    letter-spacing: -0.04em;
    font-family: $headings-font-family;
    color: $color-one;

  }
}

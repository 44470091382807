$breakpoints: (
    small: 480px,
    medium: 768px,
    large: 992px,
    xlarge: 1200px
);

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;
$grid-gutter-width: 30px;
$container-large-desktop: (1170px + $grid-gutter-width);

// Colors grayscale
$cream: #FDFCF9;
$white: #ffffff;
$black: #000000;
$blackish: $black;
$gray-lighter: #F9F8F5;
$gray-light: #C6B9B3; // Expandia ljusgrå
$gray: #ad9e93; // Expandia grå
$gray-dark: #A5A5A5;
$gray-darker: #606060;
$text-color: #404040;
$beige: #EEEDE2;
$beige-dark: #bfb0aa;

// Colors brand
$tint: 10%;
$color-brand: #C5282D;
$color-brand-hover: darken($color-brand, $tint);
$color-one: #ff1d01; // Expandia Röd
$color-one-hover: darken($color-one, $tint);
$color-two: #79170D; // Expandia Mörkröd
$color-two-hover: darken($color-two, $tint);
$color-three: #7bc1e2; // Expandia himmelblå
$color-three-hover: darken($color-three, $tint);
$color-three-dark: #80AADC; // Expandia himmelblå
$color-three-dark-hover: darken($color-three-dark, $tint);
$color-four: #9fecb5; // Expandia Teal
$color-four-hover: darken($color-four, $tint);
$color-four-dark: #7DB78F; // Expandia Teal
$color-four-dark-hover: darken($color-four-dark, $tint);
$color-five: #FFDB55; // Expandia Gul
$color-five-hover: darken($color-five, $tint);
$color-five-dark: #ecc625;
$color-five-dark-hover: darken($color-five-dark, $tint);
$color-six: #5d4837; // Expandia jord
$color-six-hover: darken($color-six, $tint);
$color-seven: #ff0283; // Expandia purple
$color-seven-hover: darken($color-seven, $tint);
$color-eight: #45b182; // Expandia purple
$color-eight-hover: darken($color-eight, $tint);

$color-nine: #9CE3BD; // Expandia green
$color-nine-hover: darken($color-nine, $tint);

$color-ten: #A1CBAE; // Expandia green dark
$color-ten-hover: darken($color-ten, $tint);



$red-gradient: linear-gradient(70deg, $color-one 40%, $color-seven 100%);


$brand-primary: $color-one;
$brand-primary-hover: $color-one-hover;
$brand-primary-light: lighten($brand-primary, $tint);
$brand-primary-dark: darken($brand-primary, $tint);

$brand-danger: #ea5f4a;

$link-color: $brand-primary;
$link-hover-color: $brand-primary;
$state-danger-text: $brand-danger;
$headings-color: $text-color;

// brand
$color-facebook: #3b5998;
$color-twitter: #55acee;
$color-googleplus: #dd4b39;
$color-google: #d62d20;
$color-youtube: #bb0000;
$color-linkedin: #007bb5;
$color-instagram: #125688;
$color-whatsapp: #4dc247;
$color-pinterest: #cb2027;
$color-snapchat: #fffc00;
$color-dropbox: #007ee5;
$color-flickr: #ff0084;
$color-spotify: #00e461;
$color-vimeo: #aad450;
$color-wordpress: #21759b;

// Borders
$border-radius-base: 3px;
$box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.3);
$box-shadow-hover: 0px 6px 18px 0px rgba(0,0,0,0.3);
$border-gray: #BCB1A9;

// Margins
$margin: 20px;
$gap: 30px;
$margin-bottom: $gap;

// Animation
$transitionTime: 0.25s;
$easeCurve: ease-out;

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$headings-font-family: 'United Sans Bold', sans-serif;
$headings-font-family-medium: 'United Sans Medium', sans-serif;
$headings-font-family-light: 'United Sans Light', sans-serif;
$font-family-demi: $font-family-sans-serif;
$font-family-bold: $font-family-sans-serif;

$font-weight-light: 100;
$font-weight-base: 300;
$font-weight-medium: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$headings-font-weight: $font-weight-semibold;

$link-hover-decoration: none;
$font-size-big: 18px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-extra-small: 12px;
$line-height-normal: 26px;
$input-color-placeholder: $gray;

// Z-index positions
$tab-bar-zindex: 500;
$banner-zindex: 1000;
$nav-more-zindex: 600;

// Forms
$input-height-base: 44px;
$padding-base-horizontal: 20px;
$input-color: $brand-primary;
$input-border: $gray-light;
$placeholder: $gray-light;

// Tables
$table-cell-padding: 6px;
$table-bg: $gray-lighter;

//***********************************************************//

// https://codepen.io/jakealbaugh/post/css4-variables-with-fallbacks-using-sass

// color map
$color: (
        text: $color-two,
        background: $brand-primary,
);

// font map
$font: (
        family: (
                body: (Merriweather, Georgia, serif),
                head: (Roboto, Helvetica, sans-serif)
        ),
        weight: (
                body: 300,
                head: 300,
        ),
        size: (
                body: 18px,
                h1: 2.4em,
                h2: 2em,
                h3: 1.6em,
                h4: 1.4em,
        ),
        leading: (
                body: 1.7,
                head: 1.2,
        )
);

// associate variable map name to an actual variable map
$var-maps: (
        color: $color,
        font: $font
);

// which property uses which var map
// you would edit this for each property you want to use variables for
$props: (
        background-color: color,
        color: color,
        font-family: (font, family),
        font-size: (font, size),
        font-weight: (font, weight),
        line-height: (font, leading),
);

// the variable mixin takes a property and variable name
@mixin v($prop, $var, $show-fall: true) {
  // get the property's map name(s)
  $map-name: map-get($props, $prop);
  $nest-name: null;
  $nest-map-name: null;
  $map: null;
  $var-fall: null;
  $var-output: null;
  // if a list, we need to go deeper
  @if type-of($map-name) == list {
    $nest-name: nth($map-name, 1);
    $nest-map-name: nth($map-name, 2);
  }
  // if it is a nested map
  @if $nest-name {
    // get the map from nested map-name
    $map: map-get($var-maps, $nest-name);
    // get the nested map
    $nest-map: map-get($map, $nest-map-name);
    // get the var value fro mthe nested map
    $var-fall: map-get($nest-map, $var);
    $var-output: var(--#{$nest-name}-#{$nest-map-name}-#{$var});
  } @else {
    // get the map from map name
    $map: map-get($var-maps, $map-name);
    // fallback, grab the variable's value from the map
    $var-fall: map-get($map, $var);
    $var-output: var(--#{$map-name}-#{$var});
  }
  // if show standard is not overridden to be null
  @if $show-fall {
    #{$prop}: $var-fall;
  }
  // css4 variable output
  #{$prop}: $var-output;
}

// setup the css4 variable definitions
:root {
  // for each variable map
  @each $var-map-name, $var-map in $var-maps {
    // for each variable in the variable map
    @each $var, $val in $var-map {
      // if it is a map, go another level deep
      @if type-of($val) == map {
        // each in the map
        @each $var-n, $val-n in $val {
          // do the definition
          #{--$var-map-name}-#{$var}-#{$var-n}: $val-n;
        }
      } @else {
        // do the definition
        #{--$var-map-name}-#{$var}: $val;
      }
    }
  }
}

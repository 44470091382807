%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin icomoon {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    } @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  } @else {
    font-size: $fs-font-size;
  }
}

/*
 * Generate responsive attribute
 */
@mixin get-variable-attribute($attribute, $sizes, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $size in $sizes {
    @if $fs-breakpoint == null {
      @include make-attribute-size($attribute, $size);
    } @else {
      // If $size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-attribute-size($attribute, $size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-attribute-size($attribute, $fs-font-size) {
  #{$attribute}: nth($fs-font-size, 1);
}

// Mixin for breakpoints
@mixin media-query($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin media-query-max($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin red-slider-dots() {




    li {
      background-color: $gray-dark;
      border-color: transparent;
      transform: scale(.8);
      width: 16px;
      height: 16px;
      list-style: none;
      display: inline-block;
      margin-right: 5px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: $text-color;
    }
      &:last-of-type {
        margin-right: 0;
      }

      button {
        display: none;
      }

      &.slick-active  {
        cursor: auto;
      }

      &.slick-active:before {
        border-color: $text-color;
                background-color: $text-color;
                transform: scale(1);
        content: "";
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        border-radius: 50%;
      }
    }

}


@mixin normalize-button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: inherit;
  display: flex;
  align-items: center;
  appearance: none;

}

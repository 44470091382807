/*
 * Background Colors
 */

@mixin row-column-bg-color($color-name, $color-value) {
  .component-column > .bg-#{$color-name},
  .component-row.bg-#{$color-name} .row-wrapper {
    background: $color-value;
  }
}

@include row-column-bg-color('black', $black);
@include row-column-bg-color('white', $white);
@include row-column-bg-color('expandia-white', $cream);
@include row-column-bg-color('grey', $gray-dark);
@include row-column-bg-color('grey-light', $gray-light);
@include row-column-bg-color('beige', $beige);
@include row-column-bg-color('grey-dark', $gray-dark);
@include row-column-bg-color('one', $red-gradient);
@include row-column-bg-color('brand', $color-one);
@include row-column-bg-color('two', $color-two);
@include row-column-bg-color('three', $color-three);
@include row-column-bg-color('four', $color-four);
@include row-column-bg-color('five', $color-five);
@include row-column-bg-color('six', $color-six);
@include row-column-bg-color('eight', $color-eight);
@include row-column-bg-color('nine', $color-nine);
@include row-column-bg-color('ten', $color-ten);


/*
 * Background Image
 */

.bg-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &.bg-center {
    background-position: center;
  }
  &.bg-center-bottom {
    background-position: bottom center;
  }
}

/*
 * Positioning
 */


.row-wrapper {
  > .container > .row,
  > .row {
    position: relative;
    z-index: 2;
  }
}

@include media-query(large) {
  .row-wrapper {
    display: flex;
    > .container {
      align-self: stretch;
    }
    > .container > .row,
    > .row {
      display: flex;
    }

    &.container-fluid {
      width: calc(100% + 30px);
      >.row {
        width: 100%;
      }
    }
  }

  .vertical-stretch {
    align-self: stretch !important;
    .wpb_wrapper {
      display: flex;
      height: 100%;
    }
  }

  .vertical-align-top {
    align-items: flex-start;
    &.component-column {
      align-self: flex-start;
    }
    //Inherit the rows setting on stretched columns
    .vertical-stretch .wpb_wrapper {
      align-items: flex-start;
    }
    //Setting on a stretched column
    &.vertical-stretch .wpb_wrapper {
      align-items: flex-start;
    }
  }

  .vertical-align-center {
    align-items: center;
    &.component-column {
      align-self: center;
    }
    //Inherit the rows setting on stretched columns
    .vertical-stretch .wpb_wrapper {
      align-items: center;
    }
    //Setting on a stretched column
    &.vertical-stretch .wpb_wrapper {
      align-items: center;
    }
  }

  .vertical-align-bottom {
    align-items: flex-end;
    &.component-column {
      align-self: flex-end;
    }
    //Inherit the rows setting on stretched columns
    .vertical-stretch .wpb_wrapper {
      align-items: flex-end;
    }
    //Setting on a stretched column
    &.vertical-stretch .wpb_wrapper {
      align-items: flex-end;
    }
  }
}

.component-accordion {
  margin: 0;

  @media (max-width: $screen-xs-max) {
    margin: 0 -15px;
  }

  .accordion-wrapper {
    &.open {
      .title {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .accordion-heading {
    cursor: pointer;

    i {
      float: right;
      transition: transform 300ms ease;
    }

    button {
      background: $beige;
      padding: 20px 25px 18px;
      display: block;
      width: 100%;
      text-align: left;
      margin: 0;
      text-transform: none;
      box-shadow: none;
      outline: 0;
      border: none;
      border-bottom: 2px solid $white;
      box-shadow: none;

      .title {
        font-family: $font-family-sans-serif;
        text-transform: none;
        font-weight: $font-weight-semibold;
        color: $text-color;
        line-height: 25px;
        position: relative;
        margin: 0;
        font-size: 19px;
      }
    }

  }

  .accordion-body {
    background: $white;
    display: none;
    padding: 20px;
  }


  .has-icon {
    .accordion-heading{
      margin-bottom: 2px;
      button {
        background: $white;
        border: none;

        .title {
          font-size: 19px;
          line-height: 26px;

          .icon.txt {
            display: inline-block;
            transform: scale(1.85);
            margin-right: 25px;
          }

          .icon-chevron-down {
            font-size: $font-size-base;
          }
        }
      }
    }

    .accordion-body{
      margin-bottom: 2px;
    }
  }

}

.component-breadcrumbs {

  .breadcrumb-wrapper {
    overflow: hidden;
    background-color: $placeholder;
  }

  .breadcrumbs {
    margin: 0;
    padding: 20px 0;
    list-style: none;
    overflow: hidden;

    li {
      float: left;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 600px;
      line-height: 20px;
      color: $blackish;
      vertical-align: middle;

      .icon {
        margin-right: 10px;
        font-size: 18px;
        vertical-align: sub;
      }
    }

    li.separator {
      margin: 0;

      .icon {
        margin-right: 10px;
        font-size: 10px;
        color: $placeholder;
        vertical-align: baseline;
      }
    }

    li a {
      line-height: 20px;
      color: $blackish;

      &:hover {
        color: $placeholder;
      }
    }

    li.active {
      text-decoration: underline;
    }
  }
}

.component-button {
  @include clearfix;
  margin-bottom: $margin;
  margin-top: 7px;
  display: inline-block;
  margin-right: $margin;
  &:last-child {
    margin-right: 0;
  }
  &.align-left {
    text-align: left;
  }

  &.align-center {
    margin-left: auto;
    margin-right: auto;
    display: table;
  }

  &.align-right {
    margin-left: auto;
    display: table;
  }

  &.float-btn {
    float: left;
    margin-right: 20px;
  }
}
.wpb_wrapper .float-btn:nth-child(2) {

}

.fancybox-slide--iframe .fancybox-content {
  width  : 100%;
  max-width  : 800px;
  margin: 0;

  .fancybox-button{

    &.fancybox-close-small {
      transform: translate(50%, 50%);
      background: $color-one;
      opacity: 1;
      right: 0;
    }

    svg path {
      fill: $white;
    }
  }


}

.component-contact {

    .description {
        padding: 0;

        @media (min-width: $screen-xs-max) {
            padding: 0 0 0 15px;
        }


        .h1 {
            margin: 0;
            display: none;

            @media (min-width: $screen-xs-max) {
                display: block;
            }
        }

    }


    .details {
        .heading-left {
            text-align: center;
        }
        margin-bottom: $gap;
        text-align: center;
        padding-left: 0;

        .featured {
            max-width: 360px;
            margin-bottom: $margin;
            width: 100%;
            padding-left: 0;
        }

        @media (min-width: $screen-xs-max) {
            margin-bottom: 0;

            .featured {
                margin-bottom: 0;
                max-width: 100%;
            }
            .heading-left {
                margin: $margin/2 0;
                text-align: left;
            }
        }
    }

    .detail {
        display: block;
        font-size: 80%;
        margin-bottom: 3px;
        text-align: center;

        a {
            color: $black;
        }

        &.linkedin {
            margin-top: $margin;

            a {
                color: $brand-primary;
            }
        }

        @media (min-width: $screen-xs-max) {
            text-align: left;
        }
    }
}

.corporate-contact {
    padding-bottom: $gap;
    margin-bottom: $gap;
    position: relative;

    .h2 {
        margin-top: $margin/2;
    }

    &:before {
        background: $gray-light;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        height: 1px;
    }
}

.corporate-details {
    float: left;
    margin-right: $gap;

    span {
        display: inline-block;
        font-weight: 600;
    }
}
.wpb_column {
  .wpb_wrapper {
    position: relative;
  }

  &.padding-large {
    @media (min-width: $screen-xs-max) {
      padding: 30px;
    }

  }
  &.padding-xlarge {
    @media (min-width: $screen-xs-max) {
      padding: 60px;
    }
  }
  &.padding-xxlarge {
    @media (min-width: $screen-xs-max) {
      padding: 100px;
    }
  }
  &.padding-none {
    @media (max-width: $screen-xs-max) {
      padding: 0 !important;
    }
  }


  &.has_bg_image,
  &.has_bg_color {
    > .wpb_wrapper {
      padding: 15px;
      height: 100%;

    }
  }
}

.component-contactlist {
  background: $white;
  .list-header {
    padding: 25px 20px 10px 20px;
    .component-heading {
      margin-bottom: 0;
    }
  }
  .contacts-col{
  
    @media (min-width: 768px) {
      padding: 10px !important;
    }
   
  }
  .list-wrapper {
    .contacts-wrapper {
      overflow: hidden;
      .contact-row {
          display: flex;
          flex-wrap: wrap;
          margin: 0 !important; 
        
        &:after,
        &:before {
          display: none;
        }
      }
      .contact__filter {
        margin-bottom: 30px;
        margin-left: 10px;
        @media (max-width: 768px) {
          text-align: left;
          margin-left: 15px;
        }
        select {
          background: $beige;
          min-width: 292px;
          height: 41px;
          text-decoration: none;
          font-weight: normal;
          border-radius: 3px;
          margin: 10px 10px 10px 0px;
          border-right: 10px solid #eeede2;
        }
      }
      .row-column{
        margin: 10px !important;
      }
      .component-contactsingle {
        .contact-body {
          .contact-image {
          }
          .contact-text {
          }
        }
      }
    }
  }
}

.component-contactsingle {
  padding-left: 0;
  padding: 0 0 30px 0;

  .contact-image {
    max-height: 320px;
    overflow: hidden;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }
  .contact-text {
    padding: 15px 0px 0px 0px;
    border-radius: 0 0 5px 5px;
    .name {
      color: $text-color;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 10px;
      line-height: 30px;
    }
    .info {
      font-size: 16px;
      list-style: none;
      padding-left: 0;
      margin: 15px 0;
      line-height: 20px;
      li {

        a{
          color: $text-color !important;
          font-weight: inherit !important;
        }
      }
    }
    .email {
      font-size: 16px;
      color: #D33834;
      text-decoration: none;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.component-contentbox {
  box-sizing: border-box;
  padding: $margin;
  position: relative;
  z-index:  3;
  background-color: $gray-lighter;
  border-radius: 5px;

  @media (max-width: 768px) {
    margin: 15px 0;
  }

  @media screen and (min-width: $screen-sm) {
    padding: $gap;
  }

  >div:last-child {
    margin-bottom: 0 !important;
  }

  @mixin heading-color($color-name, $color-value, $color-text) {
    &.section-bg-#{$color-name}{
      background: $color-value;
      color: $color-text;
    }
  }

  @include heading-color('black', $black, $white);
  @include heading-color('grey', $gray, $white);
  @include heading-color('grey-light', $gray-light, $white);
  @include heading-color('white', $white, $text-color);
  @include heading-color('one', $color-one, $white);
  @include heading-color('two', $color-two, $white);
  @include heading-color('three', $color-three, $white);
  @include heading-color('four', $color-four, $white);
  @include heading-color('five', $color-five, $white);
  @include heading-color('six', $color-six, $white);
}

.component-cookiealert {
  animation-name: slideUp;
  animation-duration: 0.8s;
  background-color: $white;
  box-shadow: 0 -8px 20px rgba(0,0,0,0.16);
  flex-direction: column;

  @media (min-width: $screen-xs) {
    flex-direction: row;
  }

  padding: $gap/2;
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: center;

  .alert__text {
    display: inline-block;
    color: $text-color;
    font-size: $font-size-small;
    margin-bottom: 0;
    margin-right: auto;
    margin: 0;
    padding: 0;

    @media (max-width: $screen-md-max) {
      margin-bottom: $margin/2;
    }

    a {
      color: $text-color;
    }
  }

  .btn {
    margin-left: auto;
    width: 100%;
    float: right;

    @media (min-width: $screen-xs) {
      width: auto;
    }



  }
}

$plugin-height: 74px;

/* Fade in up
------------------------------------ */
@-webkit-keyframes slideUp {
  0% {
    transform: translateY($plugin-height);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY($plugin-height);
  }

  100% {
    transform: translateY(0);
  }
}

.component-counter {
  display: flex;
  justify-content: center;

  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }


  .tick {
    font-family: $headings-font-family;
  }

  .tick-flip,.tick-text-inline {
    font-size:2.5em;
    line-height: 1.3;
  }

  .tick-label {
    margin-top:1em;font-size:1em;
  }

  .tick-char {
    width:1.5em;
  }

  .tick-text-inline {
    display:inline-block;text-align:center;min-width:1em;
  }

  .tick-text-inline+.tick-text-inline {
    margin-left:-.325em;
  }

  .tick-group {
    margin:0 .5em;text-align:center;
  }

  .tick-flip-panel {
    color: darken($brand-primary, 10) !important;
    background-color: $white;
  }

  .tick-flip-panel-text-wrapper {
    line-height: 1.45 !important;

  }

  .tick-flip {
    border-radius:5px !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .tick-flip-panel-back {
    &:after {
      border-top: 1.22px solid $brand-primary;
      background-image: none;
      // background-image: linear-gradient(180deg,rgba(0,0,0,.2) 1px,rgba(0,0,0,.05) 0,transparent 30%)
    }
  }

  .tick-flip-card {
    &:after,
    &:before {
      content: "";
      position: absolute;
      left: -4px;
      top: calc(50% - 4px);
      width: 7px;
      height: 11px;
      background: $brand-primary;
      z-index: 2;
    }

    &:after {
      left: auto;
      right: -4px;
    }
  }

  .group {
    >span:nth-last-of-type(4n){
      margin-right: 9px !important;
    }
  }

  .txt {
    padding: 20px 0;
    color: $white;
    font-size: 19px;
    line-height: 27px;
    font-weight: 500;
    font-family: $font-family-base;
    text-align: center;
  }

  .tick-flip-panel-front-text{
    margin-top: 2px;
  }
  
  .tick-flip-panel-back-text .tick-flip-panel-text-wrapper {
    height: 200%;
    top: -89%;
  }
}

.component-divider.big-gap hr {
  margin-top: $margin * 2.5;
  margin-bottom: $margin * 2.5;
}


.component-downloadablefiles {
  margin-bottom: $margin;
  width: 100%;

  @media (max-width: $screen-xs-max) {
    max-width: none;
    width: 100%;
  }

  .head {
    display: flex;
    align-items: center;

    .heading {
      display: block;
    }
  }

  .text {
    margin-bottom: $margin;
  }

  .show-all {
    .btn {
      margin-top: $margin;
      display: table;
    }
  }

  .files {
    ul {
      list-style: none;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    ul li {
      display: block;
    }

    a {
      line-height: 100%;
      display: inline-block;
      margin-bottom: $margin/2;

      @media (max-width: $screen-xs-max) {
        font-size: $font-size-small;
      }

      span {
        vertical-align: middle;
        text-transform: uppercase;
        //@extend %link-underline;
      }

      .icon {
        margin-right: 5px;
        font-size: 24px;
        vertical-align: middle;
        &:before {
          vertical-align: baseline;
        }
      }

      &:hover {
        color: $blackish;
      }
    }

    ul li:last-child a {
      margin-bottom: 0;
    }
  }

  &.color-white {
    .wrapper {
      background-color: $white;
    }
  }

  &.color-grey-light {
    .wrapper {
      background-color: $gray-lighter;
    }
  }

  &.color-brand {
    .wrapper { background-color: $brand-primary; }
    .head .heading { color: $white; }
    .text { color: $white; }
    .files a { color: $white; }
    .files a:hover { color: rgba($white, 0.8); }
  }
}

.component-downloadablefileswithtabs {
    margin-bottom: $margin;
    margin-top: $margin;
    width: 100%;
  
    @media (max-width: $screen-xs-max) {
      max-width: none;
      width: 100%;
    }

    .tabs {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      gap: 12px;
      @media (max-width: 500px) {
        padding-left: 0;
      }
      .tab {
        font-size: 14px;
        font-weight: 400;
        background: $gray-light;
        border-radius: 10px;
        color: $cream;
        padding: 2px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media (max-width: 500px) {
          font-size: 14px;
        }
        @media (max-width: 374px) {
          padding: 5px;
        }
        &.active {
          background:#5A4839;
        }
      }
    }

    .filter-and-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      @media (max-width: 500px) {
        flex-direction: column;
        margin-bottom: 20px;
      }
      select {
        background: $beige;
        min-width: 167px;
        height: 41px;
        text-decoration: none;
        font-weight: 400;
        border-radius: 3px;
        margin: 10px 10px 10px 0;
        border-right: 10px solid $beige;
        color: $text-color;
        
        @media (max-width: 500px) {
          width: 100%;
        }
      }
      input {
        @media (max-width: 500px) {
          width: 100%;
          margin-top: 10px;
          padding: 6px 25px;
        }
      }

      .search {
        background: $beige;
        min-width: 295px;
        height: 41px;
        text-decoration: none;
        font-weight: 400;
        border-radius: 3px;
        margin: 10px 10px 10px 0;
        border-right: 10px solid $beige;
        color: $text-color;
      }
    }
  
    .head {
      display: flex;
      align-items: center;
  
      .heading {
        display: block;
      }
    }
  
    .text {
      margin-bottom: $margin;
    }
  
    .show-all {
      .btn {
        margin-top: $margin;
        display: table;
      }
    }
    .tab-files {
      &:not(.active) {
        display: none;
      }
    }
    .files, .tab-files {
      ul {
        list-style: none;
        list-style-type: none;
        margin: 0;
        padding: 0;
        border-bottom: 2px solid $beige;
      }
  
      ul li {
        display: block;
        border-top: 2px solid $beige;
        padding: 20px 0;
      }
  
      a {
        color: $text-color;
        font-weight: 700;
        line-height: 20px;
        display: inline-block;
        font-size: 16px;  
        @media (max-width: 500px) {
          font-size: 13px;
        }
        span {
          vertical-align: middle;
          //@extend %link-underline;

          .file-size{
            font-weight: 400;

          }
        }
  
        .icon {
          color: $brand-primary;
          margin-right: 5px;
          font-size: 24px;
          vertical-align: middle;
          &:before {
            vertical-align: baseline;
          }
        }
  
        &:hover {
          color: $brand-primary;
        }
      }
  
      ul li:last-child a {
        margin-bottom: 0;
      }
    }
  
    &.color-white {
      .wrapper {
        background-color: $white;
      }
    }
  
    &.color-grey-light {
      .wrapper {
        background-color: $gray-lighter;
      }
    }
  
    &.color-brand {
      .wrapper { background-color: $brand-primary; }
      .head .heading { color: $white; }
      .text { color: $white; }
      .files a { color: $white; }
      .files a:hover { color: rgba($white, 0.8); }
    }
  }
  
[v-cloak] {
  display: none;
}

.overlay-spinner {
  opacity: 0.5 !important;
  pointer-events: none;
}

.component-dynamaker {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 100px;

  @media screen and (min-width: 990px) {
    .slide-enter-active {
      -moz-transition-duration: 1s;
      -webkit-transition-duration: 1s;
      -o-transition-duration: 1s;
      transition-duration: 1s;
      -moz-transition-timing-function: ease-in;
      -webkit-transition-timing-function: ease-in;
      -o-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
    }

    .slide-leave-active {
      -moz-transition-duration: 1s;
      -webkit-transition-duration: 1s;
      -o-transition-duration: 1s;
      transition-duration: 1s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    .slide-enter-to,
    .slide-leave {
      max-height: 500px;
      overflow: hidden;
    }

    .slide-enter,
    .slide-leave-to {
      overflow: hidden;
      max-height: 0;
    }
  }

  @media screen and (max-width: 990px) {
    height: 90vh;
    background: $gray-lighter;
    margin-top: 44px;

    .slide-enter-to,
    .slide-leave {
      max-height: 500px;
      overflow: hidden;
    }

    .slide-enter,
    .slide-leave-to {
      overflow: hidden;
      max-height: 0;
    }
  }

  .dynamaker-view {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 990px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .sidebar {
    z-index: 1;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    background: $white;
    width: 30%;
    margin-right: 22px;
    min-width: 245px;

    @media screen and (max-width: 990px) {
      order: 2;
      width: 100%;
      padding: 0;
      margin: 0;
      gap: 0;
      background: $gray-lighter;
      height: auto;
    }

    .sidebar-section {
      @media screen and (max-width: 990px) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
      }
    }

    .desktop-other {
      display: block;

      @media screen and (max-width: 990px) {
        display: none;
      }
    }

    .mobile-other {
      display: none;

      @media screen and (max-width: 990px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .mobile-submit-button-wrapper {
      display: none;
      @media screen and (max-width: 990px) {
        background: #f9f8f5;
        display: flex;
        justify-content: center;
        padding: 0px 21px 21px 21px;
      }
    }

    p {
      transform: translateY(-50%);
      font-family: $font-family-sans-serif;
      font-size: 11px;
    }

    h2 {
      font-size: $font-size-base;
      text-transform: inherit;
      font-family: $font-family-sans-serif;
      margin: 0;
      padding-bottom: 7px;
      position: relative;
      letter-spacing: 0;

      &:hover {
        cursor: pointer;
      }

      .icon {
        font-size: 13px;
        padding-right: 11px;
      }
      @media screen and (max-width: 990px) {
        display: none;
      }
    }

    label {
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: $font-size-small;
      line-height: 20px;
      padding: 10px 0 14px 0;
    }

    .sidebar-actions {
      background: $gray-lighter;
      width: 100%;
      padding: 14px;
      margin-top: -6px;
      margin-bottom: 15px;

      @media screen and (max-width: 990px) {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 8%;
        padding: 2% 8%;
        max-width: 400px;
      }
    }

    .seperator {
      border: none;
      border-radius: 30px;
      margin-bottom: 17px;
      height: 0.75px;
      background: #9f968b;

      @media screen and (max-width: 990px) {
        display: none;
      }
    }

    .disabled {
      background-color: #c0bdbc !important;
    }

    .disabled-text {
      color: #c0bdbc !important;
    }
  }

  .dynamaker {
    width: 80vw;
    height: 75vh;
    position: relative;
    padding: 60px 0 0 0;
    background: $gray-lighter;

    @media screen and (min-width: 600px) and (max-width: 990px) {
      height: 60%;
    }

    @media screen and (max-width: 599px) {
      height: 40%;
    }

    @media screen and (max-width: 990px) {
      width: 100%;
      padding: 20px 20px 35px 20px;
      order: 1;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: none;
      position: relative;
    }

    .zoom-out-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      @media screen and (max-width: 600px) {
        margin-right: 0;
      }

      button {
        border: none;
        background: transparent;
      }

      .hover-text {
        position: absolute;
        right: -40px;
        top: 35px;
        background: #eeede2;
        padding: 10px;
        opacity: 0.6;
        border-radius: 5px;
        color: black;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .tabs {
      position: absolute;
      top: -19px;
      transform: translateY(-50%);
      z-index: 1;
      display: flex;
      flex-direction: row;
      gap: 6px;

      @media screen and (max-width: 990px) {
        left: 0;
      }
    }

    .btn-tabs {
      border: none;
      padding: 9px 18px 7px 18px;
      background: $beige;
      color: $text-color;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: $font-size-base;
      min-width: 107px;
    }

    .btn-tabs:hover {
      background: linear-gradient(180deg, #e2e1d6 0%, #eeede2 75%);
    }

    .active-btn-tabs {
      background: $gray-lighter;
    }

    .active-btn-tabs:hover {
      background: $gray-lighter;
    }

    .submit-button-wrapper {
      button:disabled,
      button[disabled] {
        border: 1px solid #999999;
        background-color: red !important;
        color: #666666;
      }

      @media screen and (max-width: 990px) {
        position: fixed;
        bottom: 0;
        max-height: 300px;
        left: 0;
        z-index: 7;
        top: auto;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .submit-button {
      position: absolute;
      border-radius: 5px;
      top: -52px;
      right: 0;
      min-width: 213px;

      @media screen and (max-width: 990px) {
        display: none;
      }
    }

    .data {
      position: absolute;
      width: 100%;
      top: 30px;
      left: 30px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 60px;
      align-items: flex-start;
    }

    .statitics {
      display: flex;
      gap: 10px;
      flex-direction: row;
    }

    @media screen and (max-width: 990px) {
      top: 10px;
      right: 0;
    }

    p {
      font-size: 14px;
    }
  }

  .range {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 20px;

    .left {
      padding-right: 5px;
    }

    .right {
      padding-left: 5px;
    }
  }

  .input-range {
    -webkit-appearance: none;
    height: 0.75px;
    background-color: #9f968b;
    position: relative;
    margin-bottom: 30px;
    width: 90%;
    max-width: 180px;

    @media screen and (max-width: 990px) {
      max-width: 250px;
    }

    &:before {
      content: "";
      background: #9f968b;
      position: absolute;
      bottom: 0;
      left: 0px;
      height: 8px;
      width: 0.7px;
      top: 0;
      transform: translateY(-50%);
    }

    &:after {
      content: "";
      background: #9f968b;
      position: absolute;
      bottom: 0;
      right: 0px;
      height: 8px;
      width: 0.75px;
      top: 0;
      transform: translateY(-50%);
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 19px;
      width: 19px;
      border-radius: 50%;
      background: $red-gradient;
      border: 3px solid $gray-lighter;

      &:hover {
        cursor: pointer;
      }
    }

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }

  .toggle-input {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .toggle-input-mobile {
    max-width: 300px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .toggle {
    position: relative;
    width: 40px;
    height: 20px;
    background-color: $text-color;
    border-radius: 30px;
    padding: 0 !important;

    &:hover {
      cursor: pointer;
    }

    &:after {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: $gray-lighter;
      top: 1px;
      left: 1px;
      transition: all 0.5s;
    }
  }

  .checkbox {
    display: none;

    &:checked + .toggle::after {
      left: 21px;
    }

    &:checked + .toggle {
      background-color: $color-four-dark;
    }
  }

  .gform_footer {
    justify-content: flex-end;
  }

  .gform_footer input[type="submit"] {
    float: right;
    min-width: 127px;
    font-size: 16px;
  }

  .gform_footer {
    padding: 16px 0 31px 0;
  }

  .mobile-menu {
    display: none;

    @media screen and (max-width: 990px) {
      display: flex;
      gap: 12px;
      overflow: auto;
      padding: 11px;
      background: $white;
    }
  }

  .mobile-menu-button {
    background: white;
    border: 1px solid #c6b9b3;
    border-radius: 5.65419px;
    min-width: 105.62px;
    min-height: 42.97px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 23px;
    color: #c6b9b3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active {
    border: 0.75px solid #404040;
    color: #404040;
  }
}

#dynamaker-app {
  width: 100%;
  height: 100%;
  max-width: 1200px;

  @media screen and (max-width: 990px) {
    background: $gray-lighter;
  }
}

body {
  background-color: $white;
}

.confirmation {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

#dynamaker-form {
  display: none;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow-y: auto;
    z-index: 99999;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;

    @media screen and (max-width: 990px) {
      left: 0;
      top: 0;
      transform: none;
      width: 100vw;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: hidden;
      position: fixed;
    }

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px 0;
      background: rgba(0, 0, 0, 0.4);

      @media screen and (max-width: 990px) {
        background: #fdfcf9;
      }
    }

    .inner {
      width: 90%;
      max-width: 557px;
      margin: 50px 0;
      background: $cream;
      opacity: 0.96;

      @media screen and (max-width: 990px) {
        width: 100%;
        background: transparent;
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 52px;

        p {
          margin: 0;
        }
      }

      button.close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;
        border: none;
        background: transparent;
        line-height: 0;
      }

      .info {
        padding: 0px 78px 41px 78px;
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 370px);
        overflow: auto;

        @media screen and (max-width: 990px) {
          padding: 10%;
          max-height: 100vh;
        }

        .download {
          position: relative;
        }
        .spinner {
          display: none;
          position: absolute;
          bottom: 30px;
          right: 55px;
        }

        h4 {
          text-align: center;
          line-height: 30px;
        }

        p {
          text-align: center;
          font-size: $font-size-base;
          line-height: 20px;
          font-weight: 400;
          margin: 0 0 27px 0;
        }

        label {
          font-size: $font-size-base;
          padding: 15px 0 7px 0;
          margin: 0;
          font-weight: 400;
        }

        .dynamaker-input {
          input {
            width: 100%;
            background: $beige;
            border-radius: 3px;
            border: none;
            padding: 6px 15px;
            min-height: 41px;
          }

          label {
            font-size: $font-size-base;
            padding: 0px 0px 5px 0px;
            margin: 0;
            font-weight: 400;
          }
        }

        .button-area {
          display: flex;
          justify-content: flex-end;
        }

        .submit-form-button {
          min-width: 127px;
          padding: 10px;
          line-height: 20px;
          margin-top: 31px;
        }

        .input-hidden {
          display: none !important;
        }
      }

      .img {
        line-height: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

#dynamaker-summary {
  width: 100%;

  .summary {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10%;

    @media screen and (max-width: 791px) {
      width: 100%;
      flex-direction: column;
    }
  }

  h2 {
    font-size: 32px;
  }

  .background {
    font-family: $headings-font-family-light;
    color: $white;
    line-height: 45px;
    max-width: 310px;

    span {
      background: linear-gradient(70deg, #ff1d01 40%, #ff0283);
      color: $white;
      box-decoration-break: clone;
      padding: 8px 10px 0;
      font-style: normal;
      font-weight: 300;
      font-size: 40px;
      line-height: 45px;
    }
  }

  p {
    margin: 0;
    font-weight: 400;
    font-family: $font-family-sans-serif;
    line-height: 28px;
  }

  .bold {
    font-weight: 600;
  }

  .column {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .column-1 {
    width: 55%;

    @media screen and (max-width: 791px) {
      width: 100%;
      margin-bottom: 50px;
    }

    p {
      line-height: 20px;
      max-width: 520px;
    }
  }

  .column-1 {
    p {
      font-size: 19px;
      font-weight: 400;
      line-height: 28px !important;
      font-family: $font-family-sans-serif;
    }
  }

  .column-2 {
    width: 45%;

    @media screen and (max-width: 791px) {
      width: 100%;
    }

    span {
      font-size: 21px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0;
    }

    .total {
      font-weight: 600;
    }

    .seperator {
      border: none;
      border-radius: 30px;
      margin-bottom: 17px;
      height: 2px;
      background: $beige;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 16px;
    }

    .heading {
      margin-top: 5px !important;
    }

    .price {
      font-size: 25px;
      font-family: $headings-font-family;
      background: radial-gradient(
          400% 1403.94% at 78.39% -86.49%,
          #ec005f 16.15%,
          #ff1f00 66.67%,
          #ef4136 100%
        ),
        #ef4136;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin: 0;
      font-weight: 600;
    }
    
    .price-info{
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      padding: 0;
    }
  }

  .img-wrapper {
    width: 100%;
    position: relative;
    background: $gray-lighter;
    display: flex;
    justify-content: center;
    align-items: center;

    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background: linear-gradient(
        180deg,
        #84acb5 -17.82%,
        rgba(218, 237, 255, 0) 100%
      );
      mix-blend-mode: multiply;
    }

  }

  .exterior-image{
    height: 60vh;
    overflow: hidden;

    @media screen and (max-width: 791px) {
      height: 40vh;
    }

    img{
      padding: 0;
      width: 100vw;
      height: auto;

      @media screen and (max-width: 1200px) and (min-width: 791px) {
        object-fit: cover;
      }

      @media screen and (max-width: 791px) {
        object-fit: contain;
      }
    }
  }

  .layout{
    height: 70vh;
    overflow: hidden;

    @media screen and (max-width: 791px) {
      height: 30vh;
    }
    img{
      width: auto;
      max-width: 100vw;
      max-height: 100%;
      width: auto;
      position: absolute;
      object-fit: cover;
      object-position: center;
      padding: 40px;

      @media screen and (max-width: 691px) {
       width: 100vw;
       padding: 0px;
       height: auto;
      }
    }
  }
}

.component-emptyspace  {
    @media (max-width: 768px) {
        display: none;
    }
}

.component-event {
  .meta {
    margin-bottom: 20px;

    .categories, .date {
      display: inline-block;
    }

    .date {
      margin-right: 10px;
    }
  }

  .content {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;

    .container {
      padding: 0;
      width: 100%;
    }

    .component-row {
      margin: 0;
    }
  }

  .pager {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    .previous {
      float: left;
    }
    .next {
      float: right;
    }
  }
}

.component-eventlist {
  .event-wrapper {
    border: 1px solid $gray-light;
    padding: 20px;
    margin-bottom: 20px;

    .date {
      display: inline-block;
      margin-bottom: 5px;
      color: $placeholder;
      font-size: 18px;
      line-height: 100%;
    }
    .heading,
    .description,
    .link {
      color: $gray;
      font-size: 16px;
    }
    .heading {
      margin-bottom: 2px;

      &:hover {
        color: $placeholder;
      }
    }
    .description {
      margin-bottom: 10px;
    }
    .link {
      font-weight: 300;

      &:hover {
        color: $placeholder;
      }

      .icon {
        margin: 0;
        color: $placeholder;
        font-size: 14px;
        vertical-align: baseline;
      }
    }
  }
  .pager {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    .previous {
      float: left;
    }
    .next {
      float: right;
    }
  }
}

.component-eventslider {
  .heading-wrapper {
    padding-left: 80px;
    padding-right: 80px;
    overflow: hidden;

    @media (max-width: $screen-sm-max) {
      padding-left: 40px;
      padding-right: 40px;
    }

    .component-heading {
      width: 49%;
      display: inline-block;
      @media (max-width: $screen-sm-max) {
        width: 100%;
      }
    }

    .terms-wrapper {
      width: 49%;
      display: inline-block;
      text-align: right;
      float: right;

      @media (max-width: $screen-sm-max) {
        float: none;
        width: 100%;
        text-align: left;
      }
    }

    a {
      color: $gray;
      font-weight: 300;
      padding-left: 5px;
    }

    a.is-active {
      text-decoration: underline;
      color: $blackish;
    }

    a:hover,
    a:active,
    a:focus {
      color: $blackish;
    }
  }

  .events-wrapper {
    padding: 0 80px;

    @media (max-width: $screen-sm-max) {
      padding: 0 40px;
    }
  }

  .slick-track {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
  }

  .event-wrapper {
    width: 33%;
    padding: 30px;
    border-left: 1px solid $gray-light;
    height: 100%;
    flex: 1;

    &:focus {
      outline: none;
    }

    .date {
      display: inline-block;
      margin-bottom: 10px;
      color: $placeholder;
      font-size: 26px;
    }
    .heading,
    .description,
    .link {
      color: $gray;
      font-size: 16px;
    }
    .heading {
      margin-bottom: 5px;
      font-size: 20px;

      &:hover {
        color: $placeholder;
      }
    }
    .description {
      margin-bottom: 20px;
    }
    .link {
      font-weight: 300;

      &:hover {
        color: $placeholder;
      }

      .icon {
        margin: 0;
        color: $placeholder;
        font-size: 14px;
        vertical-align: baseline;
      }
    }
  }

  .slick-list {
    border-right: 1px solid $gray-light;
  }

  .slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    border: 0;
    background: transparent;
    border-radius: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    transform-style: preserve-3d;
    font-size: 0;
    z-index: 100;
    text-align: center;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:before {
      @include icomoon;
      font-size: 40px;
      content: $icon-chevron-left;
      color: $blackish;
      line-height: 40px;
    }

    &:hover:before {
      color: $placeholder;
    }

    &.slick-next {
      right: 0;

      &:before {
        content: $icon-chevron-right;
      }
    }

    &.slick-prev {
      left: 0;
    }

    @media (max-width: $screen-sm-max) {
      width: 20px;
      height: 20px;

      &:before {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
}

.component-faqlist {
  margin-bottom: 20px;
  .faq__list {
    padding: 0;
    margin: 0;

    >div:first-child{
      border-top: 2px solid $white;
    }

    li {
      list-style: none;
      margin-bottom: 2px;
    }
  }


  .faq_answer {
    display: none;
    padding: 0 30px 30px 30px;
    background: $white;
    color: $text-color;
  }
}


.component-row .accordion-body .row-wrapper {
  background: inherit;
}
.component-footer {
  position: relative;
  align-self: flex-end;
  width: 100%;
  background: $beige;

  .loaded-as-iframe & {
    display: none;
  }

  .footer-wrapper {
    width: 100%;
    padding-top: 40px;
    position: relative;
    z-index: 2;
    .container {
      max-width: 100%;
    }
    .footer-logo {
      width: 175px;
      height: auto;
      margin-bottom: 15px;
    }

    .site-logo {
      text-align: center;
      @media (min-width: $screen-md) {
        text-align: left;
        display: flex;
        justify-content: space-between;
      }
    }
    .footer_email {
      display: flex;
      align-items: center;
      justify-content: start;
      @media (max-width: $screen-md) {
        justify-content: center;
      }
    }




    .links {



      @media (max-width: $screen-md) {
        display: flex;
        flex-wrap: wrap;

        .info {
          order: 1;
        }
        .contact {
          order: 3;
        }
        .modules {
          order: 4;
        }
        .lang {
          order: 2;
        }
      }




      a {
        color: $text-color;
        font-weight: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .lang {
      ul,li {
        list-style: none;
        margin: 0;
        padding: 0;

        img {
          margin-right: 7px;
        }
      }
    }

  }
  .footer-icons {
    margin-top: 40px;
    padding: 40px 0;
    background: $gray-light;
    text-align: center;
    z-index: 2;
    position: relative;
  }

  .component-newsletterform {
    #mc_embed_signup_scroll {
      margin: 50px auto 0 auto;

    }
  }

  .some {
    text-align: center;
    padding: 30px;

    @media (min-width: $screen-md) {
      padding: 10px 30px;
      display: flex;
      flex-direction: column;
    }

    a {
      font-size: 30px;
      color: $text-color;
      margin: 0 2px;
      display: inline-block;

      &:hover {
        color:$black;
      }

      &.instagram {
        color: $white;
        padding: 0px 4px;
        font-size: 22px;
        background: $text-color;
        border-radius: 5px;
        transform: translateY(-3px);


        &:hover {
          background:$black;
        }

        i {
          display: inline-block;
          transform: translateY(-0.5px);
        }
      }
    }
  }
}


.gform_wrapper,
#mc_embed_signup {

  .no-underline{
    .gfield_select{
      text-decoration: none;
    }

  }
  input[type="text"], input[type="number"], input[type="tel"], input[type="email"] {
      font-weight: 300;
      padding: 12px 15px !important;
      border-radius: 5px !important;
      background: $white;
      font-family: $font-family-base;
      -webkit-appearance: none;
      border-radius: 0;
      color: $black;
      line-height: 1 !important;
      width: 100% !important;
  }

  @media (min-width: $screen-sm) {
    .gfield.left,
    .gfield.right {
      float: left;
      width: 50%;
      display: inline-block;
      clear: none;
      &.lastone {
        float: none;
      }
      &.fullwidth {
        width: 100%;
      }
    }
    .gfield.right {
      float: right;
    }
  }

}

.gform_wrapper ul {
  @extend .list-unstyled;
}

.gform_wrapper {
  .mdl-textfield {
  }

  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label,
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
    color: $black;
  }

  .mdl-textfield--floating-label.is-focused .mdl-textfield__label {
    color: $brand-primary;
  }

  .mdl-textfield__label:after {
    background-color: $brand-primary;
    height: 2px;
    bottom: 15px;
  }

}

.gform_wrapper {
  position: relative;
}

.section-bg--white .gform_wrapper {
  background-color: $gray-lighter;
}

label.mdl-textfield__label, .gfield_label {
  color: $black;
  font-weight: 400;
  font-family: $font-family-base;
}

.file-input-wrapper,
.ginput_container_radio,
.ginput_container_list {
  margin-bottom: 10px;
}

.fileinput_label {
}

.ginput_container_fileupload {
  input {
  }
}

.ginput_container_select {
  select {
    background: #fff;
    background-size: 16px;
    outline: 0;
    display: inline-block;
    line-height: 1;
    height: 45px;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: underline;
    border: 0;
  }
}

li.gfield_error {
  margin-bottom: 30px;
}

.gsection_description {
  margin-bottom: 25px;
}

.gfgeo-hidden-fields {
  display: none !important;
}

.gfgeo-map-wrapper {
  border: none !important;
}

.ginput_container.ginput_container_checkbox,
.ginput_container.ginput_container_radio {
  margin-bottom: $margin-bottom !important;
}

.gfield_checkbox {
  margin-bottom: $margin-bottom;
  li {
    margin-bottom: 0;
  }

  label, input[type=checkbox] {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    margin-left: 0;
    cursor: pointer;
    font-weight: 100;
  }

  label {
    position: relative;
  }

  input[type=checkbox] + label:before {
    content: '';
    background: transparent;
    border: 2px solid #757575;
    color: $brand-primary;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 0;
    margin-right: 5px;
    margin-bottom: 2px;
    text-align: center;
    line-height: 20px;
    font-size: 21px;
    border-radius: $border-radius-base;
  }

  input[type=checkbox]:checked + label:before {
    @include icomoon;
    line-height: 17px;
    content: $icon-checkmark;
    background: $brand-primary;
    color: $white;
    border-color: $brand-primary;
    font-size: 13px;
  }

  input[type=checkbox] {
    opacity: 0;
    position: absolute;

    &:checked + label:after {
      opacity: 1;
    }
  }
}

.gfield_radio {
  margin-bottom: $margin-bottom;

  li {
    margin-bottom: 0;
  }

  input[type=radio] {
    opacity: 0;
    position: absolute;
  }

  label {
    position: relative;
  }

  label, input[type=radio] {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
    font-weight: 100;
    margin-left: 0;
  }

  input[type=radio] + label:before {
    content: '';
    background: transparent;
    border: 2px solid #757575;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 0;
    margin-right: 5px;
    margin-bottom: 2px;
    text-align: center;
    line-height: 25px;
    font-size: 24px;
    border-radius: 50%;
  }

  input[type=radio]:checked + label:before {
    background: $brand-primary;
    box-shadow: inset 0px 0px 0px 3px $white;
    border-color: $brand-primary;
  }
}

.ginput_container {
  input[type='text'],
  input[type='tel'],
  input[type='email'] {
    //font-weight: 700;
  }
}

.gform_wrapper {
  .gform_footer {
    input[type='submit'],
    button[type='submit'] {
      margin: 0;
      width: auto;
      background-color: $color-four;
      color: $text-color;


      &:hover, &:focus, &:active {
        box-shadow: $box-shadow-hover;
      }
    }
  }
}

.gfield .mdl-textfield {
  width: 100%;
}

.gfield {
  .mdl-textfield--floating-label {
    &.is-focused {
      .mdl-textfield__label {
        left: 0;
        top: 4px;
      }
    }
    .mdl-textfield__label {
      top: 39px;
      left: 15px;
    }
  }
}

.gfield .mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: $color-two;
}

.gfield .mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: $color-two;
}

.gfield_required {
  color: $color-two;
  font-size: 12px;
  margin-left: 5px;
}

// Inverted colors
.invert-colors .gform_wrapper {

  color: $white;

  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label,
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
  .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
  label.mdl-textfield__label, .gfield_label{
    color: $white;
  }

  .mdl-textfield__label:after {
    background-color: $white;
  }

  .ginput_container_select {
    select {
      box-shadow: 0 0 0 2px $white;
    }
  }

  .mdl-textfield__input {
    border-bottom: 1px solid rgba($white,.5);
    color: $white;
  }



  .gfield_checkbox {
    input[type=checkbox] + label:before {
      border: 2px solid $white;
      color: $white;
    }

    input[type=checkbox]:checked + label:before {
      background: $white;
      color: $brand-primary;
      border-color: $white;
    }
  }


  .gfield_radio {
    input[type=radio] + label:before {
      border: 2px solid $white;
    }

    input[type=radio]:checked + label:before {
      background: $white;
      box-shadow: inset 0px 0px 0px 3px $brand-primary;
      border-color: $white;
    }
  }

  .gform_footer {
    input[type='submit'],
    button[type='submit'] {
      background-color: $white;
      color: $brand-primary;

      &:hover {
        background-color: rgba($white, 0.8);
      }
    }
  }

}

.clear-multi{
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 125px;
  justify-content: space-between;
  align-items: center;
}

.ginput_container_date{
  display: flex;
  justify-content: center;
  align-items: center;
}

.gfield_time_hour, .gfield_time_minute {
  width: 100% !important;
  display: flex;
  align-items: center;
  flex-direction: row;

  input{
    width: 100% !important;
    padding: 0 !important;
    color: red;
    min-width: 60px !important;
  }

  i{
    margin-left: 10px;
  }
}

// One liner
form.one-liner {
  display: flex;
  align-items: flex-end;

  .gform_footer {
    width: auto;
    padding: 1px 0 2px;

    input[type='submit']{
      line-height: 1 !important;
      min-height: auto !important;
    }
  }

  .gform_body {
    flex-grow: 1;
    .gfield {
      padding-right: 10px !important;
    }
  }

}
@include media-query(large) {
  body:not(.home) {
    .component-header {
      animation: none;
    }
  }
}
.component-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  height: 80px;
  z-index: 9998;
  animation: pop-in .6s ease-out forwards;
  opacity: 1;
  overflow: hidden;

  .loaded-as-iframe & {
    display: none;
  }
  
  @include media-query(large) {
    height: 100px;
    overflow: visible;
  }
  &.pinned {
    height: 56px;
    box-shadow: 0 0 20px rgba(0,0,0,.25);
    .header-wrapper {
      height: 56px;
      .site-logo {
        img {
          width: 100px;
        }
      }
      .header-navigation {
        nav.navigation {
          ul.menu, ul.menu-more-dropdown {
            margin-bottom: 0;
            li.active:after {
              display: none;
            }
            li {
              padding-bottom: 0;
            }
          }
        }
      }
      .top-search {
        padding-bottom: 10px;
        .header-navigation.right {
          margin-bottom: -10px;
        }

      }


    }

    .sub-menu,
    .blur {
      top: 56px !important;

      .admin-bar & {
        top: 88px !important;
      }
    }
  }
  &.show-nav {
    height: 100vh;
    padding-bottom: 40px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .header-wrapper {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 $gap;
    background-color: $cream;
    &.open {
      height: 100%;
    }
    @include media-query(large) {
      height: 100px;
    }
    .site-logo {
      z-index: 9997;
      align-self: center;
      img {
        width: 100px;
        height: auto;
        transition: width 100ms ease;
        @include media-query(large) {
          width: 138px;
        }
      }
    }
    .mobile-navigation {
      z-index: 1;
      align-self: center;
      display: inline-block;
    }
    .header-navigation {
      &.primary {
        display: none;
        @include media-query(large) {
          z-index: 4;
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
      &.right {
        display: none;
        @include media-query(large) {
          display: block;
          margin-bottom: -23px;
        }
      }
      ul {
        display: inline-block;
        vertical-align: middle;
        transition: margin-bottom 300ms ease;
      }

      nav.navigation.primary {
        @include media-query(large) {
          margin: 0 auto;
        };
      }
      nav.navigation.primary,
      nav.navigation.right {
        display: none;
        @include media-query(large) {
          display: block;
        };
        ul {
          list-style: none;
          li {
            float: left;
            position: relative;
            padding-bottom: 15px;
            margin-left: 10px;
            margin-right: 10px;

            &.new::before{
              content: "Nyhet!"; 
              position: absolute;
              top: 0;
              left: 0px;
              font-family: 'Roboto';
              font-weight: 500;
              color: #EF4136;
              font-size: 10px;
          }

            &.active {
              @include media-query(medium) {
                &:after {
                  content: ' ';
                  position: absolute;
                  bottom: 0;
                  left: -10px;
                  height: 5px;
                  width: calc(100% + 20px);
                  background: $text-color;
                  opacity: 1;
                  transition: opacity 0.2s ease;
                }
              }
            }
            &:hover {
              a {
                color: $color-one;
              }
              ul.sub-menu {
                display: block;
              }
              &:after {
                opacity: 0;
              }
              .blur {
                opacity: 1;
                transform: translateY(0);
              }
            }
            a {
              color: $text-color;
              font-size: 18px;
              font-weight: 500;
              padding: 10px 0;
              cursor: pointer;
              transition: color 400ms ease;
            }
            ul.sub-menu {
              z-index: 9999;
              display: none;
              position: fixed;
              top: 100px;
              width: 100%;
              left: 0;
              background: $white;
              padding: 30px 15px 50px;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 60px 40px -7px;


              .admin-bar & {
                top: 132px;
              }

              &.custom-order {
                > div {
                  > div {
                    &:nth-child(1) {
                      order: -2;
                    }
                    &:nth-child(2) {
                      order: -1;
                    }
                    &:nth-child(3) {
                      order: -3;
                    }
                  }
                }
              }

              li {
                padding: 0;
                display: block;
                width: 100%;
                &.active {
                  a span {
                    color: $gray-light;
                  }
                  &:after {
                    display: none;
                  }
                }
                &:hover {
                  a {
                    span {
                      color: $gray-light;
                    }
                  }
                }
                a {
                  padding: 0 !important;

                  span {
                    padding: 5px 0;
                    display: block;
                    color: $text-color;
                    white-space: nowrap;
                    transition: color 400ms ease;
                    font-size: 24px;
                  }
                }
              }

              .container.flex {
                display: flex;


                .col.first {
                  border-right: 1px solid $beige-dark;
                  margin-right: 30px;
                }
              }
            }

            .blur {
              position: fixed;
              top: 100px;
              left: 0;
              width: 100%;
              height: 100vh;
              background: hsla(0,0%,100%,.1);
              z-index: 99;
              transform: translateY(100%);
              opacity: 0;
              pointer-events: none;
              //transition: opacity .2s ease;
              -webkit-backdrop-filter: blur(20px);
              backdrop-filter: blur(5px);
              //transition-delay: 0.2s;

              .admin-bar & {
                top: 132px;
              }
            }

          }
        }
      }
      ul.menu-more-dropdown {
        margin-left: 10px;
        position: relative;


        &.active {
          li {
            i {
              transform: rotate(-180deg);
            }
          }
        }
        li {
          text-transform: uppercase;
          font-weight: bold;
          position: relative;
          padding: 10px 0;
          cursor: pointer;
          i {
            transition: transform 300ms ease;
            display: inline-block;
            margin-left: 5px;
          }
          .dialog {
            display: none;
            width: 20px;
            height: 20px;
            background: $white;
            transform: rotate(45deg);
            position: absolute;
            bottom: -10px;
            left: 50%;
            margin-left: -10px;
          }
          .dropdown-menu {
            z-index: 9999;
            display: none;
            position: fixed;
            top: 100px;
            width: 100%;
            left: 0;
            background: #fff;
            padding: 15px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

            .admin-bar & {
              top: 132px;
            }


            li {
              padding: 0;
              &.active {
                a span {
                  color: $text-color;
                }
                &:after {
                  display: none;
                }
              }
              &:hover {
                a {
                  span {
                    color: $gray-darker;
                  }
                }
              }
              a {
                padding: 0 !important;
                span {
                  display: block;
                  color: $text-color;
                  white-space: nowrap;
                  transition: color 400ms ease;
                }
              }
            }
          }
        }
      }
    }
    .top-search {
      z-index: 5;
      display: none;
      transition: padding-bottom 300ms ease;
      @include media-query(large) {
        display: flex;
        align-items: flex-end;
        padding-bottom: 25px;
      };

      .component-button {
        margin: 0;
      }

      .search-container {
        display: none;
        i.toggle-search {
          color: $color-one;
          font-size: 30px;
          margin-bottom: 2px;
          cursor: pointer;
        }
        form {
          display: inline-block;
          vertical-align: bottom;
          margin-right: 15px;
          width: 0;
          overflow: hidden;
          transition: width 200ms ease;
          &.show-search {
            width: 200px;
          }
          input {
            border: 0;
            padding: 6px 15px;
            border-radius: 5px;
            width: 100%;
            outline: 0;
          }
        }
      }
      .btn-contact {
        margin-left: 15px;
      }
    }
  }
  .primary-nav-sub-menu {
    display: none;
    @include media-query(large) {
      display: block;
      width: 100%;
      background: $color-one;
      text-align: center;
      z-index: 99;
      height: 0;
      line-height: 40px;
      overflow: hidden;
      animation: sub-menu-height .4s ease-out forwards; //height 40px
      animation-delay: .1s;
      .page-name {
        cursor: pointer;
      }
      .sub-menu.nav, .sub-menu-content {
        height: 100%;
      }
      .page-name {
        position: relative;
        text-transform: uppercase;
        font-weight: 500;
        color: #fff;
        margin-right: 30px;
        font-size: 12px;
        text-decoration: underline;
        &:after {
          content: $icon-chevron-right;
          font-family: 'icomoon' !important;
          vertical-align: middle;
          position: absolute;
          right: -17px;
          bottom: 0;
          line-height: 1;
        }
      }
      ul {
        display: inline;
        position: relative;
        list-style: none;
        overflow: auto;
        padding: 10px 0;
        margin: 0;
        line-height: 1;
        li {
          display: inline-block;
          z-index: 3;
          position: relative;
          &.bg-active {
            a {
              color: $color-one;
            }
          }
          a {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            color: $white;
            padding: 0 10px;
            cursor: pointer;
            transition: color 200ms ease;
            letter-spacing: 0.05em;
          }
        }
        .floating-bg {
          z-index: 2;
          width: 0;
          height: 24px;
          background: $white;
          border-radius: 5px;
          top: 8px;
          position: absolute;
          -webkit-transition: 0.3s ease;
        }
      }

    }
  }
}
#toggle-nav, #toggle-nav-main {
  display: inline-block;
  z-index: 9998;
  cursor: pointer;
  @include media-query(large) {
    display: none;
  }
  .toggle-nav-lines {
    position: relative;
    display: inline-block;
    width: 24px;
    border-top: 3px solid $color-brand;
    margin-right: 5px;
    border-radius: 5px;
    vertical-align: middle;
    transition: all 0.3s ease;
    &:after {
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      top: -10px;
      left: 0px;
      width: 100%;
      border-top: 3px solid $color-brand;
      border-radius: 5px;
    }
    &:before {
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      top: 4px;
      left: 0px;
      width: 100%;
      border-top: 3px solid $color-brand;
      border-radius: 5px;
    }
  }
  .toggle-nav-text {
    color: $color-one;
    display: inline-block;
    vertical-align: middle;
    margin-right: 2px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 900;
    &.open {
      display: none;
    }
  }
  &.open {
    .toggle-nav-lines {
      border:0;
      &:after {
        transform: rotate(45deg);
        top: -3px;
      }
      &:before {
        transform: rotate(-45deg);
        top: -3px;
      }
    }
  }
}
#toggle-nav-main {
  display: inline-block;
  line-height: 1;
  .toggle-nav-text {
    vertical-align: baseline;
    color: $text-color;
  }
  .toggle-nav-lines {
    margin-top: -3px;
    border-top: 2px solid $text-color;
    margin-left: 5px;
    width: 21px;
    &:after {
      top: -7px;
      border-top: 2px solid $text-color;
    }
    &:before {
      top: 3px;
      border-top: 2px solid $text-color;
    }
  }
}

.component-heading {
  // margin-bottom: $margin;
  margin-bottom: 12px;

  h1, h2, h3, h4, h5, h6 {
    display: block;
    margin-bottom: 0;
    position: relative;
    padding-top: 2px; // Otherwise Å will be cut

    @mixin heading-color($color-name, $color-value, $tile-text) {
      &.color-#{$color-name}{
        color: $color-value;
        &.background-tile {
          line-height: 1.4;
          font-weight: $font-weight-base;
          span {
            background: $color-value;
            font-family: $headings-font-family-light;
            color: $tile-text;
            box-decoration-break: clone;
            padding: 8px 10px 0;
          }
        }
      }
    }

    @include heading-color('black', $text-color, $white);
    @include heading-color('grey', $gray, $white);
    @include heading-color('grey-light', $gray-light, $white);
    @include heading-color('white', $white, $color-one);
    @include heading-color('one', $red-gradient, $white);
    @include heading-color('two', $color-two, $white);
    @include heading-color('three', $color-three, $white);
    @include heading-color('four', $color-four, $white);
    @include heading-color('five', $color-five, $white);
    @include heading-color('six', $color-six, $white);
    @include heading-color('eight', $color-eight, $white);
    @include heading-color('brand', $color-brand, $white);

  }

  a {
    overflow: hidden;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }

  .heading-sub {
    text-transform: uppercase;
  }

  .bs-padding {
    padding: 0 15px;
  }

  &.heading-left {
    text-align: left;
  }

  &.heading-center {
    text-align: center;
  }

  &.heading-right {
    text-align: right;
  }

  .line {
    content: "";
    display: inline-block;
    width: 100px;
    height: 5px;
    background-color: $brand-primary;
  }
  &.has-pattern {
    width: 100%;
    h1, h2, h3, h4, h5, h6 {
      padding-right: 80px;
      @include media-query(small) {
        padding-right: 110px;
      }
      @include media-query(medium) {
        padding-right: 170px;
      }
      @media (max-width: 480px) {
        padding-right: 0;
      }
    }
  }

  .heading-pattern{
    position: relative;
    width: 100%;
    height: 1px;
    background: $border-gray;
    margin-top: 3px;
    &:after, &:before {

      content: ' ';
      display: block;
      position: absolute;
      background: $gray-lighter;
      border-top: 1px solid $border-gray;
      border-right: 1px solid $border-gray;
      border-left: 1px solid $border-gray;
      right: 30px;
      width: 30px;
      @media (max-width: 480px) {
        display: none;
      }
      @include media-query(small) {
        width: 60px;
      }
      @include media-query(medium) {
        right: 100px;
      }
    }
    &:after {
      bottom: 0;
      height: 9px;
      @include media-query(small) {
        height: 18px;
      }
    }
    &:before {
      bottom: 9px;
      height: 7px;
      @include media-query(small) {
        bottom: 18px;
        height: 15px;
      }
    }
  }

}

.component-hotspots {
  .inner {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-xs-max) {
      flex-direction: row;
    }

    .content {
      @media (min-width: $screen-xs-max) {
        width: 45%;
        flex-shrink: 0;
        padding-right: 87px;
      }

      h4 {
        display: block;
      }

      .txt-intro {
        margin: 15px 0 30px;
        display: block;
      }

      .spot-text {
        display: none;
        @media (min-width: $screen-xs-max) {
          display: block;
        }
      }

    }

    .spot-text {
      padding: 25px;
      background: $white;
    }


    .image {

      .img {
        position: relative;

        img {
          max-width: 100%;
        }
      }

      .spot {
        position: absolute;
        appearance: none;
        background: $white;
        border: none;
        border-radius: 20px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        color: $white;
        transition: background 0.2s ease;
        box-shadow: 0 0 0 0 rgba($white, 1);
        transform: scale(1);
        animation: pulse 2s infinite;
        padding: 0;

        @media (min-width: $screen-xs-max) {
          width: 40px;
          height: 40px;
          line-height: 40px;
        }


        &.active,
        &:hover {
          background: $brand-primary;
        }
      }

    }

    .responsive-spot-info {
      display: block;
      margin-top: 15px;
      @media (min-width: $screen-xs-max) {
        display: none;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0px rgba($white, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($white, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0px rgba($white, 0);
  }
}


.component-icon {
  position: relative;
  text-align: center;
  margin-bottom: $margin;

  .heading {
    transition: color 0.2s;
    display: block;
    margin: 0;

    &.small {
      font-size: 16px;
    }

    &.medium{
      font-size: 19px;
      line-height: 27px;
    }
  }

  .link {
    display: inline-block;
    font-weight: $font-weight-base;
    color: $text-color;
    width: 100%;
    &:hover {
      .icon {
        transform: scale(1.1);
      }
    }
  }

  .icon {
    font-size: 48px;
    transition: color $transitionTime $easeCurve;
    display: block;
    text-align: center;
    margin-bottom: $gap/2;
    transform: scale(1);
    transition: transform $transitionTime $easeCurve;

    &.mini {
      font-size: 16px;
    }
    &.small {
      font-size: 24px;
    }
    &.large {
      font-size: 100px;
    }
    &.huge {
      font-size: 200px;
    }
  }

  .text {
    display: block;
    margin: $gap/2 0 0;
    transition: color 0.2s;
    text-align: center;
  }

  .big{
    font-size: 19px !important;
    line-height: 26px !important;
  }

  &.theme-left,
  &.theme-mini {
    .top {
      display: flex;
      align-items: center;
    }
    .icon {
      margin: 0 20px 0 0;
    }

    .heading, .text {
      text-align: left;
    }
    .text {
      margin: 0;
    }
  }

  &.theme-mini {
    .icon {
      font-size: 20px;
    }
    .heading {
      font-size: $font-size-small;
      font-family: $font-family-base;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      letter-spacing: 0.01em;
    }
  }
}

.component-iconsslider {
  overflow: hidden;

  .title {
    text-align: center;
  }

  .container {
    width: auto;
  }

}
.component-iframe {
}
.component-image {
  max-width: 100%;
  margin-bottom: $margin;
  overflow: hidden;
  display: block;
  backface-visibility: hidden;
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }

  img {
    margin: 0 auto;
    transform: scale(1);
    transition: transform $transitionTime $easeCurve;
  }

  p {
    color: $text-color;
    font-weight: $font-weight-base;
  }

  @media screen and (max-width: $screen-sm) {

    &.small {
      width: 100%;
    }

    .du-ratio-1x1,
    .du-ratio-4x3,
    .du-ratio-9x16 {
      width: 50%;
      margin: 0 auto;
    }
  }
}

.component-image .img-text {
  padding-top: 10px;

  p {
    margin-bottom: 0;
    max-width: 475px;
    margin: 0 auto;
  }
}

.component-image .missing-image {
  width: 100%;
}

.component-image.small {
  width: 50%;
}

.component-image.small.center {
  margin-left: auto;
  margin-right: auto;
}

.component-image.small.right {
  margin-left: auto;
}

.component-image.round {

  .coh-resp-img-ratio-wrapper {
    border-radius: 50%;
    overflow: hidden;
    backface-visibility: hidden;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  .img-text * {
    text-align: center;
  }
}

a.component-image {
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.image-bank {
  width: 100%;
  padding-top: 34px;

  .display-none {
    display: none;
  }

  .display {
    display: flex;
  }

  .imagebank-filters {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 20px;

    @media only screen and (max-width: 700px) {
      flex-direction: column;
      padding: 20px;
    }

    button,
    input {
      width: 295px;
      height: 41px;
      margin: 0px 0 50px 0;
      background: $beige;
      border-radius: 3px;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      color: $text-color;
      font-weight: 400;

      @media only screen and (max-width: 700px) {
        margin: 0px 0 15px 0;
      }

      .icon {
        font-size: 12px;
      }

    }

    .imagebank-input::placeholder {
      color: $text-color;
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    button:hover {
      cursor: pointer;
    }
  }

  ul {
    min-width: 295px;
    background: $cream;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 20px 10px;
    position: absolute;
    z-index: 10;
    top: 100px;

    @media only screen and (max-width: 700px) {
      position: absolute;
      top: 105px
    }
  }

  li {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 2px 25px;
    position: relative;

    &:hover {
      cursor: pointer;
      color: $gray-light;
    }
  }

  .display-none {
    display: none;
  }

  .toggle-active {
    position: absolute;
    top: 3px;
    left: -20px;
    font-size: 12px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2%;
    flex-wrap: wrap;
    padding: 0px 20px;

    .img-info {
      width: 32%;
      padding-bottom: 50px;

      @media only screen and (min-width: 470px) and (max-width: 850px) {
        width: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 0px;
        align-items: flex-start;
      }

      @media only screen and (max-width: 470px) {
        width: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 20px 0px;
        align-items: flex-start;

      }

      .img-wrapper {
        position: relative;
        max-width: 380px;
        min-width: 200px;
        width: 100%;
        max-height: 320px;
        height: 320px;
        background: #F9F8F5;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          object-fit: cover;
        }

        .displayWithFullWidth {
          width: 100% !important;
          height: initial !important;
          padding: 20px;
          margin: auto;

        }
      }

      p {
        background: $red-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-family: $font-family-sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 17px;
        margin-bottom: 21px;
      }

      .icon {
        font-size: 18px;
      }

    }

    .download-button {
      display: inline-block;
      padding: 12px 20px;
      text-align: center;
      position: relative;
      border-radius: 5px;
      font-weight: 500;
      transition: all .2s ease-in-out;
      line-height: 1;
      min-width: 127;

      @media only screen and (max-width: 700px) {
        margin: 0;
      }
    }
  }
}

.component-imageslider {
  overflow: hidden;
  margin-bottom: $margin;
  position: relative;

  &.a16x9 {
    padding-bottom: 56.25%;
  }

  &.a1x1 {
    padding-bottom: 100%;
  }

  &.a4x3 {
    padding-bottom: 75%;
  }

  &.a9x16 {
    padding-bottom: 177.77%;
  }

  .slide {
    display: none;

    &.slide-1{
      display: block;
    }
  }

  &.slick-initialized {
    .slide {
      display: inline-block;
    }
  }

  &.anone .slider-wrapper {
    position: static;
    left: inherit;
    right: inherit;
    top: inherit;
    bottom: inherit;
  }

  .slider-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .slick-slide:focus {
      outline: none;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    height: 16px;
    text-align: center;
    z-index: 9;
    @include red-slider-dots();
  }

  .slick-arrow {
    display: none !important;
    @include media-query(small) {
      display: inline-block !important;
    }
    position: absolute;
    top: 50%;
    border: 0;
    background: transparent;
    border-radius: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    transform-style: preserve-3d;
    font-size: 0;
    z-index: 100;
    text-align: center;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:before {
      @include icomoon;
      font-size: 60px;
      content: $icon-arrow-left;
      color: $white;
      line-height: 60px;
      background: rgba(0,0,0,0.75);
      padding: 5px;
    }

    &:hover:before {
      color: $white;
    }

    &.slick-next {
      right: 30px;
      &:before {
        content: $icon-arrow-right;
      }
    }

    &.slick-prev {
      left: 30px;
    }

  }
}

.component-ingress {
  margin-bottom: $margin;

  p{
    margin-bottom: 0;
    letter-spacing: 0.02em;
  }

  h4{
    margin-bottom: 0;
    letter-spacing: 0.02em;
  }

  .lead-left {
    text-align: left;
  }

  .lead-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .lead-right {
    text-align: right;
    margin-left: auto;
  }

  .color-std {
    color: $text-color;
  }

  .color-black {
    color: $black;
  }

  .color-white {
    color: $white;
  }

  .variant-small{
    font-size: 19px;
    line-height: 27px;
  }

  .variant-standard {
    @include font-size($ingress-font-sizes);
  }

  .variant-bold{
    font-size: 25px;
    line-height: 34px;
    font-weight: bold;
  }
}

.component-listlist {
  margin-bottom: 20px;

  .list__list {
    padding: 0;
    margin: 0;


    .component-accordion {
      border-bottom: 2px solid #E9E8DE;
    }

    >div:first-child{
    }

    li {
      list-style: none;
      margin-bottom: 2px;
    }
  }

}


.component-row .accordion-body .row-wrapper {
  background: inherit;
}
.component-mobilemenu {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  background: $white;
  @include media-query(large) {
    display: none;
  };
  z-index: 9999;
  nav.main-nav {
     ul {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      li {
        width: 100%;
        position: relative;
        i.expand-menu {
          position: absolute;
          right: 25px;
          top: 21px;
          color: $text-color;
          transition: transform 300ms ease;
          font-size: 18px;
          &.expanded {
            transform: rotate(180deg);
          }
        }
        a {
          color: $text-color;
          display: block;
          padding: 17px 0;
          margin: 0 25px;
          font-weight: 500;
          font-size: 24px;
          border-bottom: 1px solid $gray-light;
          line-height: 1;
          text-align: start;

          &::first-letter {
            text-transform: capitalize;
          }
        }
      .expanded-menu{
        border-bottom: none;
      }
        ul {
          display: none;
          background: $beige;
          padding: 10px 0px;
          li {
            a {
              border: 0;
              color: $text-color;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  nav.secondary-nav {
    ul {
      list-style: none;
      margin: 17px 0 0 0;
      padding: 0;
      text-align: center;
      li {
        width: 100%;
        a {
          color: $black;
          display: block;
          padding: 12.5px 0;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 16px;
          line-height: 1;
        }
      }
    }
  }
  .btn-contact {
    z-index: 1;
    margin-top: 30px;
    margin-left: 25px;
  }
}

.component-logoslider {

  a {
    display: block;

    &:focus {
      outline: none;
    }
  }

  .slick-slide {
    padding: 0 1em;

    &:focus {
      outline: none;
    }
  }

  .item-bg-image {
    height: 170px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &:focus {
      outline: none;
    }
  }

  .logo-wrapper {
    margin-bottom: 70px;

  }

  .slick-dots {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    padding: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    height: 15px;
    text-align: center;

    li {
      border: 3px solid #bfbfbf;
      background-color: #bfbfbf;
      width: 17px;
      height: 17px;
      list-style: none;
      display: inline-block;
      margin-right: 5px;
      border-radius: 50%;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }

      button {
        display: none;
      }

      &.slick-active  {
        background-color: transparent;
        border-color: $brand-primary;
        cursor: auto;
      }
    }
  }
}

.component-newsletterform {
  #mc_embed_signup_scroll {
    max-width: 600px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .indicates-required {
      display: none;
    }

    input[type="email"]{
      border: none;
      text-transform: uppercase;
      margin-top: 5px;
      font-family: $headings-font-family;
      font-weight: 400;
      background: #e5e2d5;
    }

    .field-email {
      width: 100%;
      @media screen and (min-width: $screen-sm) {
        width: 55%;
      }
    }
    .field-submit {
      width: 100%;

      @media screen and (min-width: $screen-sm) {
        width: 45%;
        padding-left: 15px;
      }
    }

    .email-label {
      width: 100%;
    }

    .field-check {
      width: 100%;
      text-align: left;
      label {
        color: #fff;
      }
    }

    input[type=checkbox], input[type=radio] {
      float: left;
      margin: 2px 10px 0 0;
    }

    
    .mc-field-group {
      margin-bottom: 16px;
      display: block;
    }
  }

  #mc-embedded-subscribe {
    margin-top: 5px;
    background: $color-three;
    width: 100%;
  }

  .hide-heading {
    h2 {
      display: none;
    }
   .email-label {
      display: none;
   }
  }


  .sidebar {

    .inner {
      background: #fff;
      padding: 15px;
      margin-top: 15px;
    }

    #mc_embed_signup_scroll {
      .field-email,
      .field-submit {
        width: 100% !important;
        padding: 0 !important;
      }

      .field-check {
        label {
          color: $gray;
        }
      }
    }
  }

}

#sgpb-popup-dialog-main-div {
  background: transparent !important;
  border: none !important;

  &:after {
    content: "";
    background: url('https://expandia.se/app/uploads/2019/03/close-icon.png');
    background-size: contain;
    background-position: center center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -5px;
    right: -25px;
    cursor: pointer;
    pointer-events: none;
  }
}
.component-office {
  margin: 0 -15px;

  & > div {
    margin: 0 15px;
  }

  &.cols-4, &.cols-3, &.cols-2 {
    & > div {
      margin-bottom: $margin;
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;

    &.cols-4, &.cols-3, &.cols-2 {
      & > div {
        width: 50%;
      }
    }
  }

  @media screen and (min-width: $screen-md-min) {
    &.cols-4 {
      & > div {
        width: 25%;
      }
    }

    &.cols-3 {
      & > div {
        width: 33.333%;
      }
    }

    &.cols-2 {
      & > div {
        width: 50%;
      }
    }
  }

  a.mail {
    word-break: break-all;
  }

  .map {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;

    &.small {
      padding-bottom: 100%;
    }
  }

  .map-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h3 {
    margin: 0 0 10px 0;
    font-size: 24px;
  }

  h5 {
    margin: 0 0 10px 0;
    font-size: 16px;
  }

  .position,
  .details {
    padding: 0;
    list-style: none;
    color: $gray;
    font-size: 16px;
    font-weight: 300;
  }

  p {
    margin-bottom: 0;
    color: $gray;
    font-size: 16px;
    font-weight: 300;
  }

  .details {
    margin-bottom: 0;
  }

  .details a {
    color: $gray;
    font-size: 16px;
    font-weight: 300;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.component-officelist {
  .offices-wrapper {
    .component-officesingle {
      padding: 15px 0;
    }
    @include media-query(medium) {
      .row {
        display: flex;
        &:before {
          display: none;
        }
        .component-officesingle {
          height: 100%;
          .office-wrapper {
            height: 100%;
            .office-body {
              height: 100%;
              display: flex;
              flex-direction: column;
              .office-text {
                align-self: stretch;
                flex: 1 0 auto;
              }
              .office-image {
                flex: 0 0 auto;
              }
            }
          }
        }
      }
    }
  }
}

.component-officesingle {
  .office-image {
    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }

  .office-text {
    padding: 15px 20px 30px 20px;
    background: $white;
    border-radius: 0 0 5px 5px;
    .name {
      display: block;
      color: $text-color;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .info {
      font-size: 18px;
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 5px;
      }
    }
    .find {
      font-size: 18px;
      color: $color-one;
      text-transform: uppercase;
      text-decoration: none;
      @extend %link-underline;
    }
  }
}

.component-pagetitle {
  .bg {
    display: table;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 200px;
    width: 100%;

    @media (max-width: $screen-xs-max) {
      height: 100px;
    }
  }

  h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: $white;
  }
}

.component-postlatest {
  .component-post.latest {
    position: relative;
    padding: 0;

    .image {
      padding-bottom: 85%;
      background-position: center center;
      background-size: cover;
    }

    .content {
      padding: 25px 0;
      color: $text-color;
    }
  }
}

.component-post.list {

  margin-bottom: 0;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid $border-gray;

  .row {
    display: flex;

    @media (max-width: $screen-xs-max) {
      display: block;
    }
  }

  .read-more-image,
  .read-more-heading {
    display: block;
  }
  .read-more-heading {
    .component-heading {
      margin-bottom: 10px;
    }
  }

  .row > div {
    align-items: stretch;
  }

  .image {
    position: relative;
    margin-bottom: 20px;
    @include media-query(medium) {
      margin-bottom: 0;
    }
  }

  .unread {
    position: absolute;
    top: 10px;
    left: 10px;
    display: none;
    background: red;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 6px;
    margin-bottom: -1px;
  }

  .post-notifications-new {
    .unread {
      display: inline-block;
      z-index: 2;
    }
  }

  .pattern {
    position: relative;
    width: 100%;
  }

  .pattern:before {
    content: "";
    display: block;
    padding-top: 56.25%;
    background: url(pattern.png) no-repeat 0 0;
    background-size: cover;
    background-color: $gray
  }

  .pattern-1 {
    background-color: $gray-dark;
  }

  .meta {
    .categories, .date {
      display: inline-block;
      i {
        margin-left: 0;
        color: $color-one;
        font-size: 16px;
        vertical-align: middle;
        &:before {
          vertical-align: baseline;
        }
      }
      a {
        font-weight: 300;
        color: $color-one;
        vertical-align: middle;
      }
    }

    .date {
      color: $color-one;
      &:after {
        content: '|';
        color: $black;
        margin-left: 5px;
        margin-right: 5px;
        font-weight: bold;
      }
      span {
        vertical-align: middle;
        font-weight: 300;
      }
    }
    .categories {
      &:after {
        display: none;
      }
    }
  }

  .meta {
    font-size: 14px;
    font-weight: normal;
    color: $gray;
    margin-bottom: 10px;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 10px;
    }

    a {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }

    .tags {
      display: inline-block;

      a {
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }

  }

  .excerpt {
    font-size: 18px;
    margin-bottom: 15px;

    @media (max-width: $screen-md-max) {
      font-size: 18px;
    }
  }

  .read-more {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    color: $color-one;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    vertical-align: middle;
    @extend %link-underline;
    i {
      height: auto;
      width: auto;
      &:before {
        vertical-align: middle;
      }
    }
  }
}

.component-post.single.single {
  width: 100%;
  padding-top: 40px;
  .go-to-archive {
    position: relative;
    display: inline-block;
    margin-bottom: 60px;
    margin-top: -30px;
    margin-left: 0;
    @include media-query(medium) {
      margin-left: -15px;
    }
    color: $color-one;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    vertical-align: middle;
    @extend %link-underline;
    i {
      height: auto;
      width: auto;
      margin-left: 0;
      margin-right: 5px;
      &:before {
        vertical-align: middle;
      }
    }
  }
  .content-box {
    @include media-query(medium) {
      padding: 0 30px;
    }
  }
  .component-image {
    margin-bottom: 30px;
  }
  .meta {
    font-weight: 14px;
    margin-bottom: 10px;
    .categories, .date, .author, .share{
      display: inline-block;
      i {
        margin-left: 0;
        color: $color-one;
        font-size: 16px;
        vertical-align: middle;
        &:before {
          vertical-align: baseline;
        }
      }
      a {
        font-weight: 300;
        color: $color-one;
        vertical-align: middle;
      }
      span {
        font-weight: 300;
        vertical-align: middle;
        font-size: 14px;
      }
    }
    .categories, .date, .author {
      &:after {
        content: '|';
        color: $black;
        margin-left: 5px;
        margin-right: 5px;
        font-weight: bold;
      }
    }

    .date {
      color: $color-one;
    }

    .author, .share {
      span {
        color: $color-one;
        vertical-align: middle;
      }
    }
  }

  .content {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;

    .container {
      padding: 0;
      width: 100%;
    }

    .component-row {
      margin: 0;
    }
  }

  .pager {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    .previous {
      float: left;
    }
    .next {
      float: right;
    }
  }
}

.component-post.slider {
  .post__image {
    position: relative;
    .image__text {
      width: 100%;
      padding: 30px 0px 15px;
      color: $text-color;

      h3 {
        margin-bottom: 0;
        font-family: $font-family-base;
        letter-spacing: 0;
        text-transform: none;
      }
    }
    flex: 0 0 auto;
  }
  .post__text {
    position: relative;
    flex: 1 0 auto;

    .btn-link {
      margin-top: 15px;
      display: block;
      font-weight: $font-weight-semibold;
    }
  }

}

.component-post {

  .date {
    text-transform: lowercase;
  }

  &.single {

    img {
      width: 100%;
      height: auto;
    }

    .next-post {
      float: right;

      span {
        margin-left: 5px;
        margin-right: 0;
      }
    }

    @media (min-width: $screen-md-min) {
      .component-image {
        margin-bottom: $margin * 2;
      }
    }

    @media (max-width: $screen-sm-max) {
      .meta {
        margin-bottom: $margin;
      }
    }
  }

  .date, .category, .author {
    color: $gray-darker;
    font-size: $font-size-small;
    margin-bottom: 0;
  }

  .category a {
    font-weight: $font-weight-base;
  }

  .catdate {
    @media (max-width: $screen-md-max) {
      margin-bottom: 10px;
    }
  }
}

.component-post.list {
  overflow: hidden;
  width: 100%;
  @extend %clearfix;

}

.component-post--list__image {
  width: 40%;
  height: 350px;
  float: left;
  display: block;
  overflow: hidden;

  @media (max-width: 500px) {
    width: 100%;
    height: 250px;
  }
}

.component-post--list__body {
  padding: 35px;
  width: 60%;
  float: left;
  background-color: $gray;
  position: relative;
  height: 350px;

  @media (max-width: 500px) {
    width: 100%;
    height: auto;
  }

  &.full {
    width: 100%;
  }

  .meta, h2 {
    margin-bottom: 15px;
  }

  span {
    margin-right: 15px;
  }

  p {
    margin-bottom: 0;
    display: block;
    @include font-size($ingress-font-sizes);
  }

  .title-link {
    overflow: hidden;
    display: inline-block;
  }

  .read-more {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 60px solid $link-color;
    border-left: 100px solid transparent;

    .icon {
      margin-right: 0;
      color: $white;
      position: absolute;
      bottom: -50px;
      right: 10px;
      font-size: 20px;
    }
  }
}
.component-postlist {
  width: 100%;
  .component-post {
    margin-bottom: 50px;
  }

  .pager {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    .previous {
      float: right;
    }
    .next {
      float: left;
    }
  }
}

.component-postlistfilter {
  width: 100%;
  height: 0;
  line-height: 60px;
  background: $gray-dark;
  position: relative;
  text-align: center;
  animation: filter-menu-height .4s ease-out forwards; //height 60px
  overflow: hidden;
  .filter__inner {
    position: relative;
    z-index: 2;
    select {
      margin: 0 15px;
    }
  }
  &:after {
    content: "";
    background: url('pattern.svg') no-repeat;
    background-size: 130%;
    background-position: center -200px;
    opacity: 0.3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    @include media-query(large) {
      background-position: center;
    }
  }
}

.post-notifications-count {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  border-radius: 50%;
  background-color: #F42929;
  color: $white;
  font-family: $headings-font-family;
  text-align: center;
  text-decoration: none;
}

.component-header__right {
  .post-notifications-count {
    position: absolute;
    top: -10px;
    left: 0;
  }
}

/*
 * Mark posts unread in the post list
 */
.component-post {
  .unread {
    @extend .post-notifications-count;
    display: none;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-right: 2px;
  }

  .post-notifications-new {
    .unread {
      display: inline-block;
    }
  }
}

.component-postslider,
.component-postlatest,
.component-productslider,
.component-uspslider,
.component-iconsslider,
.component-counterslider{

  .archive-link {
    text-align: center;
    padding: 30px 0;
  }

  .post-slider {
    .slick-dots {
      padding-left: 0;
      text-align: center;
      padding-top: 20px;
      @include red-slider-dots();

    }
    .slick-list {
      overflow: visible;
      position: relative;
      min-height: 200px;
      .slick-track {
        display: flex;
        align-items: stretch;
        justify-content: center;
        @media (max-width: 767px) {
          transform: translateX(-10px);
          position: absolute;
        }
        .post-slider__post {
          opacity: 0.1;
          padding: 0 15px;
          outline: 0;
          height: auto;
          flex-direction: column;
          transition: all .22s ease;
          transform: scale(.9,.9);
          display: flex;

          @media (max-width: 767px) {
            padding-right: 20px;
            transform: translateX(-20px);
          }

          &.slick-active {
            opacity: 1;
            transform: scale(.95,.95);
            @media(max-width: 1200px) {
            }
            &:hover {
              cursor: auto;
            }
          }
          &.slick-current {
            //transform: scale(1,1);
          }
        }
      }
    }
  }

  .filter {
    padding: 15px 0;

    .filter-btn {
      appearance: none;
      border: none;
      background: $gray-light;
      border-radius: 10px;
      color: $white;
      font-size: 14px;
      font-weight: $font-weight-medium;
      padding: 1px 15px;
      transition: background 0.2s ease;
      margin: 2px 0;

      &:hover,
      &.active {
        background: $color-six;
      }

    }

  }

}
#post-slider__popup {
  display: none;
  max-width: 80px;
  transition: max-width 300ms ease;
  background: $gray-lighter;
  padding: 20px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &:after {
    content: "";
    background: url('pattern.svg') no-repeat;
    background-size: 200%;
    background-position: center -200px;
    opacity: 0.3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  &.ajax-loaded {
    padding: 60px 20px;
    @media (min-width: 769px) {
      padding: 20px;
    }
    max-width: 300px;
    .inner {
      width: 260px;
      .row {
        display: block;
      }
    }
    .spinner {
      display: none;
    }
    @include media-query(small) {
      max-width: 460px;
      .inner {
        width: 420px;
      }
    }
    @include media-query(medium) {
      max-width: 750px;
      .inner {
        width: 710px;
      }
    }
    @include media-query(large) {
      max-width: 960px;
      .inner {
        width: 920px;
      }
    }
    @include media-query(xlarge) {
      max-width: 1030px;
      .inner {
        width: 990px;
      }
    }
  }
  .inner {
    width: 40px;
    .row {
      display: none;
    }
    .post-inner {
      padding: 20px;
      .title {
        margin-bottom: 5px;
      }
      .city {
        display: block;
        margin-bottom: 15px;
        font-size: 18px;
      }
      .content {
        margin: 0;
      }
    }
    .gallery {
      width: 100%;
      .gallery__image {
        .div {
            padding-top: 75%;
            background-size: contain;
        }
        picture {
          display: block !important;
        }
      }
    }
  }
  .slick-list {
    z-index: 3;
    height: auto !important;
    position: relative;
  }
  




  .slick-arrow {
    //display: none !important;
    @include media-query(small) {
      display: inline-block !important;
    }
    position: absolute;
    top: 50%;
    border: 0;
    background: transparent;
    border-radius: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    transform-style: preserve-3d;
    font-size: 0;
    z-index: 100;
    text-align: center;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:before {
      @include icomoon;
      font-size: 30px;
      content: $icon-arrow-left;
      color: $white;
      line-height: 30px;
      background: rgba(0,0,0,0.75);
      padding: 5px;
    }

    &:hover:before {
      color: $white;
    }

    &.slick-next {
      right: 15px;
      &:before {
        content: $icon-arrow-right;
      }
    }

    &.slick-prev {
      left: 15px;
    }
  }
}
#post-slider__popup {
  .spinner {
    .sk-circle {
        margin: 0 auto;
      width: 40px;
      height: 40px;
      position: relative;
    }
    .sk-circle .sk-child {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .sk-circle .sk-child:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: $color-one;
      border-radius: 100%;
      -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
              animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    }
    .sk-circle .sk-circle2 {
      -webkit-transform: rotate(30deg);
          -ms-transform: rotate(30deg);
              transform: rotate(30deg); }
    .sk-circle .sk-circle3 {
      -webkit-transform: rotate(60deg);
          -ms-transform: rotate(60deg);
              transform: rotate(60deg); }
    .sk-circle .sk-circle4 {
      -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
              transform: rotate(90deg); }
    .sk-circle .sk-circle5 {
      -webkit-transform: rotate(120deg);
          -ms-transform: rotate(120deg);
              transform: rotate(120deg); }
    .sk-circle .sk-circle6 {
      -webkit-transform: rotate(150deg);
          -ms-transform: rotate(150deg);
              transform: rotate(150deg); }
    .sk-circle .sk-circle7 {
      -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
              transform: rotate(180deg); }
    .sk-circle .sk-circle8 {
      -webkit-transform: rotate(210deg);
          -ms-transform: rotate(210deg);
              transform: rotate(210deg); }
    .sk-circle .sk-circle9 {
      -webkit-transform: rotate(240deg);
          -ms-transform: rotate(240deg);
              transform: rotate(240deg); }
    .sk-circle .sk-circle10 {
      -webkit-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
              transform: rotate(270deg); }
    .sk-circle .sk-circle11 {
      -webkit-transform: rotate(300deg);
          -ms-transform: rotate(300deg);
              transform: rotate(300deg); }
    .sk-circle .sk-circle12 {
      -webkit-transform: rotate(330deg);
          -ms-transform: rotate(330deg);
              transform: rotate(330deg); }
    .sk-circle .sk-circle2:before {
      -webkit-animation-delay: -1.1s;
              animation-delay: -1.1s; }
    .sk-circle .sk-circle3:before {
      -webkit-animation-delay: -1s;
              animation-delay: -1s; }
    .sk-circle .sk-circle4:before {
      -webkit-animation-delay: -0.9s;
              animation-delay: -0.9s; }
    .sk-circle .sk-circle5:before {
      -webkit-animation-delay: -0.8s;
              animation-delay: -0.8s; }
    .sk-circle .sk-circle6:before {
      -webkit-animation-delay: -0.7s;
              animation-delay: -0.7s; }
    .sk-circle .sk-circle7:before {
      -webkit-animation-delay: -0.6s;
              animation-delay: -0.6s; }
    .sk-circle .sk-circle8:before {
      -webkit-animation-delay: -0.5s;
              animation-delay: -0.5s; }
    .sk-circle .sk-circle9:before {
      -webkit-animation-delay: -0.4s;
              animation-delay: -0.4s; }
    .sk-circle .sk-circle10:before {
      -webkit-animation-delay: -0.3s;
              animation-delay: -0.3s; }
    .sk-circle .sk-circle11:before {
      -webkit-animation-delay: -0.2s;
              animation-delay: -0.2s; }
    .sk-circle .sk-circle12:before {
      -webkit-animation-delay: -0.1s;
              animation-delay: -0.1s; }

    @-webkit-keyframes sk-circleBounceDelay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0);
      } 40% {
        -webkit-transform: scale(1);
                transform: scale(1);
      }
    }

    @keyframes sk-circleBounceDelay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0);
      } 40% {
        -webkit-transform: scale(1);
                transform: scale(1);
      }
    }
  }
}
.lity {
  z-index: 9999;
  background: rgba(0,0,0,0.7);
  .lity-container {
    position: relative;
    .lity-content {
      #post-slider__popup {
        display: block;
      }
    }
    .lity-close {
      opacity: 0;
      position: absolute;
      width: auto;
      height: auto;
      text-shadow: none;
      top: 15px;
      right: 15px;
      transition: opacity 300ms ease;
      font-size: 28px;
      &.ajax-loaded, &.lity-youtube {
        opacity: 1;
      }
      &.closing {
        opacity: 0;
      }
      &:before {
        font-family: 'icomoon' !important;
        content: $icon-cancel-round;
        color: $color-one;
      }
    }
  }
  &.lity-iframe, &.lity-youtube {
    .lity-container {
      .lity-close {
        opacity: 1;
        font-size: 0;
        &:before {
          font-size: 28px;
        }
      }
    }
  }
}

.component-counterslider{

  .post-slider__post {
   
    @media (max-width: 767px) {
      padding-right: 0 !important;
    }

  }
}

.component-postlatest {

  .row.inner {
    display: flex;

    @media (min-width: $screen-md) {
      flex-wrap: wrap;
    }
  }

  .item {
    padding-bottom: 60px;
    display: none;

    @media (max-width: $screen-md) {
      padding-bottom: 10px;
    }

    &.item-0 {
      display: block;

      @media (max-width: $screen-md) {
        width: 100%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: self-end;
        justify-content: space-between;
        height: 100%;
      }
    }

    &:not(.item-0) {
      @media (max-width: $screen-md) {
        display: none;
      }
    }
  }

  @media (min-width: $screen-md) {

    .item {
      display: block;

      .component-post{
        height: 100%;

        a {
          display: flex;
          flex-direction: column;
          height: 100%;

          .content {
            flex-grow: 1;
          }

          .btn {
            align-self: flex-start;
          }

        }

      }
    }

    .first .component-post.latest {
      .image {
        padding-bottom: calc(42.5% - 13px);
      }
    }
  }

}

.component-priceconfigurator {
  position: relative;

  .calc-wrapper {
    text-align: center;

    &.Platser {
      .switch {
        span.kvm {
          opacity: 0.5;
        }
        span.seats {
          opacity: 1;
        }
        .toggle span {
          left: 45px;
        }
      }
      .counter {
        .unit.m2 {
          display: none;
        }
        .unit.seats {
          display: inline-block;
        }

      }
    }
  }

  .switch {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 15px auto 15px;
    justify-content: center;

    span.kvm,
    span.seats {
      color: $black;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
    span.seats {
      opacity: 0.5;
    }

    .toggle {
      background-image: linear-gradient($white,#d2d3d4);
      width: 80px;
      height: 40px;
      position: relative;
      border-radius: 20px;
      outline: none;
      border: none;
      box-shadow: 0 2px 0 0 rgba(255,255,255,1), 0 2px 0 0 rgba(0,0,0,0.3) inset;
      cursor: pointer;
      margin: 0 15px;

      span {
        width: 30px;
        height: 30px;
        background: $brand-primary;
        border-radius: 15px;
        position: absolute;
        top: 5px;
        left: 5px;
        display: inline-block;
        transition: left 0.2s cubic-bezier(0.26, 0.86, 0.44, 0.985);
      }
    }

  }

  &.dark-background {
    .toggle-text {
      color: $white !important;
    }

    .show-price{
      color: $text-color;
      background-color: $cream;
    }
  }

 .counter {
    background-image: linear-gradient($white,#d2d3d4);
    box-shadow: 0 2px 0 0 rgba(255,255,255,1), 0 2px 0 0 rgba(0,0,0,0.3) inset;
    cursor: pointer;
    height: 100px;
    border-radius: 50px;
    width: 100%;
    max-width: 530px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px auto 30px;

    .unit.seats {
      display: none;
      margin: 0;
      white-space: nowrap;
    }
   .unit.m2 {
     display: inline-block;
     margin: 0;
     white-space: nowrap;
   }

    .btn {
      height: 70px;
      width: 70px;
      border-radius: 40px;
      margin: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      // disable text selection
      -webkit-touch-callout:none;
      -webkit-user-select:none;
      -khtml-user-select:none;
      -moz-user-select:none;
      -ms-user-select:none;
      user-select:none;
      -webkit-tap-highlight-color:rgba(0,0,0,0);

      span {
        font-size: 80px;
        line-height: 0px;
        display: inline-block;
      }

    }

    .value {
      font-weight: bold;
      padding-top: 15px;
    }

  }

}


.price-calc-result {
  position: fixed;
  background: rgba(0,0,0,0.75);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: none;
  z-index: 99999;
  justify-content: center;

  &.active {
    display: flex;
  }

  .result-container {
    width: 90%;
    max-width: 700px;
    margin: 50px 0;

    .header {
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      background: $white;

      p {
        margin: 0 auto;
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        border: none;
        padding: 15px;
      }
    }

    .output {
      background: $beige;
      text-align: center;
      padding: 30px 30px 0;
      color: $black;

      h1,h2,h3,h4,p,span {
        color: $black;
      }

      h2:not(.gform_submission_error) {
        font-weight: 400;
        margin-bottom: 10px;
        font-size: 60px;

        @media (max-width: 500px) {
          font-size: 42px;
        }
      }

      h3 {
        font-size: 42px;
        text-transform: uppercase;

        @media (max-width: 500px) {
          font-size: 24px;
        }
      }

      p {
        max-width: 400px;
        text-align: center;
        line-height: 16px;
        margin-left: auto;
        margin-right: auto;

        &.mini {
          font-size: 12px;
        }
      }


      .meta {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        margin-bottom: 30px;

        h4 {
          text-transform: none;
          font-size: 18px;
          font-weight: 400;
          margin: 0;
          padding: 10px 15px;
        }

      }

      .result-descr {
        max-width: none;
        line-height: 1.3;
        padding: 0 10px;
      }

      .expand-image {
        width: 100%;
        text-align: center;
        margin: 15px 0 15px;
      }

      .download {
        width: 100%;
        padding: 15px;
        text-align: center;
        overflow: hidden;
        position: relative;
        margin-bottom: 20px;
      }

      .button-height {
        height: 60px;
      }

      .form-height {
        height: 230px;
      }

      button.toggle {
        position: absolute;
        top: 15px;
        left: 50%;

        transform: translate(-50%,0);
        transition: all 0.5s ease;


        &.no-show {
          transform: translate(-50%, calc(-100% - 15px));
        }
      }

      .form {
        width: 100%;
        padding: 0 15px;
        transform: translate(0,230px);
        transition: transform 0.5s ease;

        &.show {
          transform: translate(0,0);
        }
      }

      .blueprint {
        margin: 0 -30px 50px -30px;
        position: relative;

        img {
          width: 100%;
          height: auto;
        }
      }

    }
  }

  .form{
    .gform_wrapper {
      max-width: 500px;
      margin: 5px auto;
    }

    form.confirmed {
      >div {
        display: none;
        &:nth-of-type(1){
          display: block;
        }
      }
    }

    .gfield_label {
      display: none !important;
    }

    .ginput_container_text,
    .ginput_container_email{
      margin: 0 !important;
    }

    .input-hidden {
      display: none !important;
    }

    .gfield_visibility_visible {
      padding-right: 0 !important;

    }

    .gform_footer {
      width: 100%;
      clear: none;
      display: inline;

      margin: 0;
      padding: 0;

      @media (min-width: 768px) {
        float: right;
        transform: translateX(-5px) scale(1.1);
        margin-top: 1px;
        width: 100px;
      }

      .btn {
        margin: 0;
        width: 100%;
      }
    }

    .gform_body {
      margin-bottom: 10px;

      @media (min-width: 768px) {
        width: calc(100% - 100px);
        float: left;
        margin-bottom: 0;

      }


      * {
        margin: 0;
      }
    }

    .gform_confirmation_message {
      background: #7bc1e2;
      color: $white;
      padding: 15px;
      font-weight: bold;
      text-align: center;
      border-radius: $border-radius-base;

    }
  }
}

.component-productslider {
  // Most styles are in post slider component

  .title {
    padding: 40px 0px 15px;
    margin: 0;
  }

  .descr {
    margin-top: 0;
  }
}
.component-quote {
  @include clearfix;
  transform-style: preserve-3d;

  .image {
    width: 35%;
    height: auto;
    float: left;

    @media (max-width: 870px) {
      display: none;
    }
  }

  &.has-image .wrapper {
    @media (min-width: 870px) {
      position: absolute;
      left: 30%;
      right: 0;
      max-width: 600px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 870px) {
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 50px;
      padding-right: 30px;
    }
  }

  &.no-image .wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 70px;
    padding-right: 30px;

    @media (max-width: $screen-xs-max) {
      padding-left: 50px;
    }
  }

  .quote {
    font-size: 24px;
    line-height: 34px;
    position: relative;
    z-index: 1;

    @media (max-width: 1245px) {
      font-size: 18px;
      line-height: 24px;
    }

    &:before {
      @include icomoon;
      content: $icon-quote;
      position: absolute;
      color: $beige;
      z-index: -1;
      opacity: 0.5;
      font-size: 100px;
      left: -50px;
      top: -20px;

      @media (max-width: 1245px) {
        font-size: 70px;
      }

      @media (max-width: 870px) {
        font-size: 50px;
        left: -20px;
        top: -12px;
      }
    }
  }

  &.no-quote .quote:before {
    display: none;
  }

  .name {

  }
}

.component-recurrentcontent {
  .container {
    padding: 0;
  }

}

.component-row .section .component-recurrentcontent .component-row {
  margin-top: 0;
}



body.single-references {
  overflow-x: hidden;

  h1, h2, .h1, .h2 {
    font-family: $headings-font-family-medium !important;
    font-weight: normal !important;

  }
}

@keyframes kenBurns {
  0% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.25) translate(10%, 2%);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }

}


.component-reference {

  .archive-link {
    text-align: center;
    margin: 30px 0;
  }

  .top-area {
    .row {
      flex-direction: column;
    }

    .component-heading {
      margin-bottom: 35px;
    }


    .row-wrapper {
      display: flex;

      .bg-image {
        animation: kenBurns 20s infinite;


        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 50%;
          background-blend-mode: multiply;
          background: linear-gradient(0deg, rgba($black, 0.75), rgba($black, 0));
        }
      }

      .container {
        display: flex;
        align-items: flex-end;
        align-self: stretch;

        @media (max-width: $screen-sm) {
          width: 100%;
        }
      }

    }

    .main-headings {

      .location {
        text-transform: uppercase;
        color: $white;
      }

      .background-tile span {
        background: none;
        padding: 0;
        font-weight: 500;
        font-family: "United Sans Medium";
      }
    }
  }


  .meta-row {
    background: $gray-lighter;


    .meta {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media (min-width: $screen-sm) {
        flex-direction: row;
        gap: 90px;
      }

      ul.data {
        width: 100%;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 90px;
        flex-direction: column;

        @media (min-width: $screen-sm) {
          width: 80%;
          flex-direction: row;
        }

        li {
          border-bottom: 1px solid $beige;
          padding: 8px 0;
          display: inline-block;
          width: 100%;

          @media (min-width: $screen-sm) {
            width: calc(50% - 45px);
          }

        }
      }

      .links {
        width: 100%;
        padding: 0;
        list-style: none;

        button {
          @include normalize-button;
          // Normalize
          padding: 4px 0;
          gap: 10px;
          font-weight: 700;

          .icon {
            display: inline-block;
            width: 30px;
            font-size: 18px;
          }

          span {
            display: inline-block;
          }

        }


        @media (min-width: $screen-sm) {
          width: 20%;
        }
      }
    }
  }


  .main-quote {
    background-image: url('quote.svg');
    background-size: 145px;
    background-repeat: no-repeat;
    background-position: 0 0;
    max-width: 1000px;
    margin: 0 auto;

    blockquote {
      padding-left: 55px;

      p {
        font-family: $font-family-sans-serif;
        font-size: 35px;
        line-height: 40px;
        color: $text-color;
        margin-bottom: 60px;
      }

      cite {
        font-family: $font-family-sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: $text-color;
        font-style: normal;
        font-weight: 300;
      }


    }
  }


  .content,
  .slideshow,
  .map,
  .blueprint {
    margin-bottom: 60px;

    .component-heading {
      margin-bottom: 40px;
    }
  }

  .slick-list {
    height: auto !important;
    position: relative;
  }

  .contact-text {
    margin-bottom: 100px;
  }

  .content {
    background: $white;
    padding: 20px;

    .stats {
      margin: 30px 0;

      .stat {
        border-bottom: 1px solid $black;
        padding: 8px 0;

        @media (min-width: $screen-sm) {
          margin-right: 15px;
        }
      }
    }

    ul {
      padding: 0;
      list-style: none;

      @media (min-width: $screen-sm) {
        columns: auto 2;
      }

      li {
        border-bottom: 1px solid $black;
        padding: 8px 0;
        display: inline-block;
        width: 100%;

        @media (min-width: $screen-sm) {
          margin-right: 15px;
        }

      }

    }

    .pdf {
      margin-top: 20px;
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
    }

    .tags {
      color: $link-color;

      i {
        margin-right: 10px;
      }
    }
  }


  .component-imageslider {
    width: 100%;
  }

}

.contact {
  .contact-expert {
    width: 100%;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    background: #EEECE4;
    flex-direction: column-reverse;

    @media (min-width: $screen-sm) {
      flex-direction: row;
      justify-content: space-between;
      padding: 30px 90px;
    }

    .image-column {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 30px;

      @media (min-width: $screen-sm) {
        width: auto;
        justify-content: flex-end;
        margin-bottom: 0;
      }
    }

    .coh-resp-img {
      border-radius: 50%;
      max-width: 350px;
      width: 100%;
    }
  }

  .component-button {
    width: 100%;

    a {
      width: 100%;
    }

    @media (min-width: $screen-sm) {
      width: auto;

      a {
        width: auto;
      }
    }

  }

}


.reference-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 99999;
  justify-content: center;
  display: none;

  &.active {
    display: flex;
  }

  .inner {
    width: 90%;
    max-width: 700px;
    margin: 50px 0;

    &.wide {
      max-width: 1170px;
    }

    &.invert {
      .header {
        background: $white;
      }

      .content {
        background: $beige;
      }
    }


    .header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-height: 52px;
      background: $beige;

      button {
        @include normalize-button;
        padding: 17px;
      }
    }

    .content {
      background: $white;
      padding: 20px;

      .view {
        display: none;

        &.active {
          display: block;
        }
      }

      .component-heading .background-tile span {
        background: transparent;
        color: $text-color;
      }

      .blueprint {
        text-align: center;

        .pdf {
          text-align: center;
        }


        .form {
          display: none;
          max-width: 500px;
          margin: 0 auto 30px;

          form {
            display: flex;
            align-items: flex-end;

            input[type="text"] {
              background-color: $gray-lighter;
            }


          }

          .input-hidden {
            display: none;
          }

          .gform_body {
            width: calc(100% - 100px);
            margin-bottom: 0;
          }

          .gform_footer {
            padding: 2px 0;
            transform: translateX(-5px) scale(1.1);
            width: 100px;
          }
        }

      }


      .slideshow {
        .component-imageslider {
          margin: 0;
        }
      }


      .map {
        .component-imageslider {
          margin: 0;
        }
      }

      .contact {
        padding: 30px 0;
        text-align: center;

        .gform_wrapper {
          max-width: 700px;
          margin: 0 auto;
        }
      }


    }


  }

}









.component-referencelisting {

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 767px) {
      align-items: center;
    }
  }

  .listing {
    display: flex;
    flex-wrap: wrap;
  }

  .post-slider__post {
    margin-bottom: 30px;
    outline: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .filter {
    padding: 15px 0;

    .filter-btn {
      appearance: none;
      border: none;
      background: $gray-light;
      border-radius: 10px;
      color: $white;
      font-size: 14px;
      font-weight: $font-weight-medium;
      padding: 1px 15px;
      transition: background 0.2s ease;
      margin: 2px 2px;

      &:hover,
      &.active {
        background: $color-six;
      }

    }

  }

}

.component-referencesselected {
  .listing {
    display: flex;
    flex-wrap: wrap;

    .reference {
      margin-bottom: 30px;
      outline: 0;
      height: auto;
      display: flex;
      flex-direction: column;
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
}

// See ReferenceListing

.component-referencessingle {
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 767px) {
    padding: 0 5px;
  }
  > a {
    font-weight: 300;
    color: inherit;
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .post__image {
    position: relative;
    .image__text {
      width: 100%;
      padding: 25px 0px 0;

      h3 {
        margin-bottom: 0;
      }
    }
    flex: 0 0 auto;
  }
  .post__text {
    position: relative;
    flex: 1 0 auto;

    .info{
      display: block;
    }


    .btn-link {
      margin-top: 15px;
      display: block;
      font-weight: $font-weight-semibold;
    }
  }
}

.component-row {
  position: relative;
  overflow: hidden;

  &.color-white {
    color: $white;
  }
  &.color-black {
    color: $text-color;
  }

  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    object-position: center;
    background: $white;
  }

  .pattern-1 {
    background: url(pattern.svg) no-repeat 0 0;
    background-size: 200%;
    background-position: center;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    opacity: 0.6;
    z-index: 1;
  }

  .row-wrapper {
    position: relative;
    .container {
      z-index: 2;
    }
  }

  &.height-large .row-wrapper {
    min-height: 650px;
    height: 100%;

    @media (max-width: $screen-xs-max) {
      min-height: 500px;
    }
  }

  &.height-medium .row-wrapper {
    min-height: 330px;
  }

  &.height-full .row-wrapper {
    min-height: 100vh;
  }

  &.height-small .row-wrapper {
    min-height: 200px;
  }

  .section {
    position: relative;
    width: 100%;

    .component-row {
      //margin-top: $margin * 2.5;

      @media (max-width: $screen-xs-max) {
        //margin-top: $margin;
      }
    }

    .container-fluid .row.inner {
      margin-bottom: $margin * 1.5;

      .col-sm-12:first-of-type {
        margin-bottom: $margin;
      }
    }
  }

  .container-fluid.full-width {
    @media (min-width: 1275px) {
      padding: 0;
    }

    .row-inner {
      margin-left: 0;
      margin-right: 0;
    }

    .vc_row {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &.no-column-padding {
    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
      .wpb_column {
        padding: 0 !important;
      }
    }
  }

  &.bkg-blend {
    .bg-image{
      opacity: 0.3;

      @supports (mix-blend-mode: multiply) {
        opacity: 1;
        background-blend-mode: luminosity;
        mix-blend-mode: multiply;
        filter: grayscale(100%);
      }
    }
  }

}

.component-row .container .component-row:last-of-type {
  .row.inner {
    margin-bottom: 0;
  }
}

.container-fluid .row.inner .col-sm-12 {
  margin-bottom: $margin;
}

.component-scrollheadermobile {
  padding: 30px 0;
  .primary-nav-sub-menu-mobile {
    @include media-query(medium) {
      display: none;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        a {
          text-transform: uppercase;
          text-decoration: underline;
          font-family: $headings-font-family;
          font-size: 20px;
          line-height: 2;
          font-weight: 500;
        }
      }
    }
  }
}

.webien-scroll-sequence {
  width: 100%;

  .scroll-content-1,
  .scroll-content-2 {
    color: #fff;
    position: absolute;
    top: 65vh;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    //max-width: 380px;
    text-align: left;
    z-index: 1;

    .heading {
      color: #fff;
      font-weight: normal;
      text-align: center;
      font-family: "United Sans Light";
      font-size: 40px;

      @include media-query(large) {
        font-size: 80px !important;
      }

    }

    p {
      text-align: center;

      @include media-query(large) {
        font-size: 25px;
      }
    }




  }


  .scroll-content-2 {
    opacity: 0;
  }



  .scroll-canvas-outer {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(217,217,217,0) 30%, rgba(60,60,60,1) 100%);
    }


    canvas.scroll-canvas {
      width: 100% !important;
      height: 100% !important;
    }

  }


}




.webien-scroll-curtain {
  position: relative;
  background: $color-brand;
  height: 90vh;
  width: 100%;


  .item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }

    .scroll-heading {
      position: relative;
      z-index: 1;
      display: inline-block;
      color: $white;
      font-family: "United Sans Light";
      font-size: 40px;

      @include media-query(large) {
        font-size: 80px !important;
      }

    }

    &.item-1 {
      z-index: 1;
      top: 0;
    }
    &.item-2 { z-index: 2; }
    &.item-3 { z-index: 3; }
    &.item-4 { z-index: 4; }

  }
}







.webien-scroll-video-mask {
  line-height: 0;
  position: relative;

  .video-curtain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .mask-outer,
    .mask-filled-outer {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }

    .mask-outer {
      top: auto;
      bottom: 0;
      height: 0vh;
    }

    .mask-filled,
    .mask-filled-inverted {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
    }

    .mask-filled {
      top: auto;
      bottom: 0;
      background: var(--video-scroll-mask-color, #fff);
      mask-image: url('video-scroll-mask.svg');
      mask-size: var(--video-scroll-mask-width, 200px);
      mask-position: center;
      mask-repeat: no-repeat;
    }


    .mask-filled-inverted {
      background: rgba(0,0,0,0.9);
      background: var(--video-scroll-mask-inverted-color, #fff);
      mask: url('video-scroll-mask.svg') center/var(--video-scroll-mask-width, 200px) no-repeat, linear-gradient(0deg, #000 0%, #000 0);
      mask-composite: exclude;
    }


  }

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;

    &.desktop {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    video.desktop {
      display: block;
    }
    video.mobile {
      display: none;
    }
  }

}

.component-share {
  .wrapper {
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    margin: 20px 0;
    padding: 20px 0;
  }

  .addthis_toolbox a {
    margin: 0;
  }
}

.component-sidemenu {
  padding-top: 5px;

  ul, li {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    border-bottom: 1px solid $gray-light;
  }

  a {
    display: block;
    padding: 20px 40px 20px 20px;
    position: relative;
    color: $placeholder;
    font-size: 18px;

    .icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -10px;
    }

    &:hover {
      background-color: $gray-lighter;
      color: $placeholder;
    }
  }

  li.active > a {
    background-color: $gray-lighter;
  }

  /**
   *  Sub menu.
   */
  ul.sub-menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms;

    li {
      border-bottom: none;
      border-top: 1px solid $gray-light;
    }

    a {
      padding-left: 30px;
      font-size: 14px;
    }
  }

  li.has-sub-menu > a .icon {
    cursor: default;
    transform: rotate(90deg);
    transition: transform 400ms;
  }

  li.sub-menu-on > a .icon {
    transform: rotate(-90deg);
  }
}

.component-sidebar {
  list-style: none;

  @media (max-width: $screen-sm-max) {
    margin-bottom: $margin;
  }

  h4 {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    color: $placeholder;
    margin-bottom: 5px;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    color: $blackish;
    text-decoration: underline;
    text-transform: capitalize;
  }

  a:hover {
    text-decoration: none;
  }
}

.component-slider {
  .slide {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    justify-content: center;

    &:focus,
    &:active {
      outline: none;
    }

    &.full {
      min-height: calc(100vh - 126px);
    }

    &.large {
      min-height: 580px;

      @media (max-width: $screen-xs-max) {
        min-height: 450px;
      }
    }

    &.medium {
      min-height: 380px;

      @media (max-width: $screen-xs-max) {
        min-height: 300px;
      }
    }

    &.small {
      min-height: 150px;
    }
  }

  .content-wrapper {
    text-align: center;

    .content-inner {
      display: inline-block;
      max-width: 75%; //TODO Variate on screen sizes
    }

    .component-heading {
      h1 {
        @include font-size((
          null: 30px,
          small: 40px,
          medium: 52px,
          large : 62px,
        ));
      }
    }

    .btn-wrapper {
      display: inline-block;
    }
  }

  .bg-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .icon-double-down {
    position: absolute;
    left: 50%;
    margin-left: -20px;
    bottom: 20px;
    font-size: 50px;
    color: #FFF;
    cursor: pointer;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .btn-wrapper .float-btn {
    @media (max-width: $screen-xs-max) {
      float: none;
    }
  }

  /**
   *  Video slide.
   */
  .slide.video {

    iframe {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .play-button {
      cursor: pointer;
      width: 75px;
      height: 75px;
      @media screen and (min-width: $screen-sm-min) {
        width: 147px;
        height: 146px;
      }

      .icon {
        font-size: 146px;
        color: $white;
        transition: color 0.2s;
      }

      &:hover {
        .icon {
          color: $placeholder;
        }
      }
    }

    .youtube-slide-close {
      display: none;
      width: 35px;
      height: 35px;
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;

      .icon {
        font-size: 35px;
        color: $white;
        transition: color 0.2s;
      }

      &:hover {
        .icon {
          color: $placeholder;
        }
      }
    }

    &.video-on {
      iframe, .youtube-slide-close {
        display: block;
      }
    }

    .video-content {
      .play-button {
        display: inline-block;
      }
      .headings {
        display: none;
        @media screen and (min-width: $screen-sm-min) {
          display: inline-block;
        }
      }
      @media screen and (min-width: $screen-sm-min) {
        font-size: 0;
        .play-button {
          vertical-align: middle;
          margin-right: -147px;
        }
        .headings {
          vertical-align: middle;
          margin-left: 147px + 20px;
          max-width: 800px;
        }
      }
    }

  }

  /**
   *  Simple slide with image and headings.
   */
  .simple-slide {
    // .row-wrapper {
    //   display: flex;
    //   align-items: center;
    // }
    // .container {
    //   // margin-left: 0;
    //   // margin-right: 0;
    //   // @media screen and (min-width: $screen-sm-min) {
    //   //   margin-left: auto;
    //   //   margin-right: auto;
    //   // }
    // }
    h5 {
      text-transform: uppercase;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    height: 16px;
    text-align: center;
    z-index: 9;

    li {
      background-color: transparent;
      border: 2px solid $gray-light;
      width: 16px;
      height: 16px;
      list-style: none;
      display: inline-block;
      margin-right: 10px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      button {
        display: none;
      }

      &.slick-active  {
        cursor: auto;
      }

      &.slick-active:before {
        border: 2px solid $placeholder;
        background-color: $placeholder;
        content: "";
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        border-radius: 50%;
      }
    }
  }

  .slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    border: 0;
    background: transparent;
    border-radius: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    transform-style: preserve-3d;
    font-size: 0;
    z-index: 10;
    text-align: center;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:before {
      @include icomoon;
      font-size: 60px;
      content: $icon-chevron-left;
      color: $white;
      line-height: 60px;
    }

    &:hover:before {
      color: rgba(255, 255, 255, 0.5) !important;
    }

    &.slick-next {
      right: 30px;

      &:before {
        content: $icon-chevron-right;
      }
    }

    &.slick-prev {
      left: 30px;
    }

    @media (max-width: $screen-sm-max) {
      width: 30px;
      height: 30px;

      &:before {
        line-height: 30px;
      }
    }
  }
}

.component-submenu {
  background-color: $gray;
  padding-top: $gap;
  transition: top 0.3s ease-in-out;

  &.headroom--not-top {
    @media (max-width: $screen-xs-max) {

      &.headroom--unpinned {
        top: 0;
      }

      &.headroom--pinned {
        width: 100%;
        left: 0;
        position: fixed;
        z-index: $banner-zindex;
        top: 60px;
      }
    }
  }

  > .container {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      width: 100%;
    }
  }

  .navigation {
    float: none;
  }

  ul {
    width: 100%;
    @include clearfix;
  }

  li {
    width: 10%;
  }

  li:nth-last-child(1):first-child,
  li:nth-last-child(1):first-child ~ li {
    width: 100%;
  }

  li:nth-last-child(2):first-child,
  li:nth-last-child(2):first-child ~ li {
    width: (100% / 2);
  }

  li:nth-last-child(3):first-child,
  li:nth-last-child(3):first-child ~ li {
    width: (100% / 3);
  }

  li:nth-last-child(4):first-child,
  li:nth-last-child(4):first-child ~ li {
    width: (100% / 4);
  }

  li:nth-last-child(5):first-child,
  li:nth-last-child(5):first-child ~ li {
    width: (100% / 5);
  }

  li:nth-last-child(6):first-child,
  li:nth-last-child(6):first-child ~ li {
    width: (100% / 6);
  }

  li:nth-last-child(7):first-child,
  li:nth-last-child(7):first-child ~ li {
    width: (100% / 7);
  }

  a {
    height: 70px;
    position: relative;
    transform-style: preserve-3d;

    span {
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 10px;

      @media (min-width: $screen-md-min) {
        padding: 0 20px;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 1px;
      height: 40px;
      background: $gray-darker;
      position: absolute;
      right: 0;
      top: 15px;
    }
  }

  li:last-of-type a:after {
    display: none;
  }

  li.active {
    margin-left: -1px;

    a {
      background-color: $white;

      &:after {
        background: $white;
      }
    }
  }

  .navigation-select {
    display: block;
    padding: $margin;
    color: $white;
    font-size: 20px;
    line-height: 20px;
    font-weight: $font-weight-medium;
    letter-spacing: 0.5px;
    cursor: pointer;

    &.active {
      span {
        &:before {
        }
      }
    }

    span {
      float: right;
    }
  }

  @media (max-width: $screen-xs-max) {
    background-color: $brand-primary-light;
    padding-top: 0;

    .container {
      padding: 0;
    }

    .navigation {
      display: none;
    }

    li {
      width: 100% !important;
    }

    li:first-child {
      border-top: 1px solid $white;
    }

    li.active {
      margin-left: 0;

      a {
        background-color: $brand-primary;
      }
    }

    a {
      color: $white;
      height: 50px;

      span {
        padding: 0 20px;
        text-align: left;
      }

      &:after {
        display: none;
      }
    }
  }
}
.component-tabbar {
  z-index: 5000;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $white;
  display: none;

  &:before {
    content: "";
    background-color: $white;
    height: 2px;
    left: 0;
    right: 0;
    top: -5px;
    position: absolute;
  }

  @media (max-width: $screen-xs-max) {
    display: block;
  }

  li {
    width: 25%;
    float: left;
  }

  li.active a:after {
    background-color: $brand-primary;
  }

  li.active a {
    color: $brand-primary;
  }

  .menu-item.contact a:before {
    content: $icon-chevron-down;
  }

  .menu-item.house a:before {
    content: $icon-chevron-down;
  }

  .menu-item.news a:before {
    content: $icon-chevron-down;
  }

  .menu-item.search a:before {
    content: $icon-chevron-down;
  }

  .menu-item:last-child a {
    margin-right: 0;
  }

  a {
    height: 50px;
    color: #757986;
    position: relative;
    text-align: center;
    margin-right: 2px;
    z-index: 1;

    &:after {
      content: "";
      background-color: #757986;
      height: 3px;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }

    &:before {
      @include icomoon;
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      font-size: 24px;
    }
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    margin-left: auto;
    line-height: 1;
    margin-right: auto;
    font-size: 12px;
  }
}

.component-teaser {

  .coh-resp-img-ratio-wrapper {
    overflow: hidden;
  }

  img {
    transform: scale(1);
    transition: transform $transitionTime $easeCurve;
  }

  a {
    display: block;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  /**
   *  Top image
   */
  &.theme--top-wide,
  &.theme--top-square {
    @media (max-width: $screen-xs-max) {
      margin-bottom: $margin/2;
    }

    .teaser_images {
      margin-bottom: $margin;
    }

    .du-ratio-1x1 {
      display: none;
    }

    .teaser__wrapper {
      padding-right: $margin/2;
    }

    .teaser__heading {
      margin-bottom: $margin/2;
    }

    .teaser__text {
      margin-bottom: $margin * 2;
    }

    .teaser__link {
      margin-bottom: 0;
    }

    @media (max-width: $screen-xs-max) {
      @include clearfix;
      padding-bottom: 5px;

      .du-ratio-1x1 {
        display: block;
      }

      .du-ratio-16x9 {
        display: none;
      }

      .teaser_images {
        float: left;
        width: 20%;
      }

      .teaser__wrapper {
        float: left;
        padding-left: 20px;
        padding-top: 0;
        padding-right: 0;
        width: 80%;
      }

      .teaser__heading {
        margin-bottom: 10px;
      }

      .teaser__text {
        line-height: 22px;

        margin-bottom: 10px;
      }

      .teaser__link {
        margin-bottom: 0;
      }
    }
  }

  &.theme--top-square {
    .du-ratio-1x1 {
      display: block;
    }

    .du-ratio-16x9 {
      display: none;
    }
  }

  /**
   *  Left square
   */
  &.theme--left-square {
    @include clearfix;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .teaser_placeholder {
      padding-top: 100%;
      position: relative;
      background-color: $color-one;
      overflow: hidden;
    }

    .du-ratio-16x9 {
      display: none;
    }

    .teaser_images {
      float: left;
      width: 30%;

      @media (max-width: $screen-xs-max) {
        width: 20%;
      }
    }

    .teaser__wrapper {
      float: left;
      width: 70%;
      padding-left: 20px;

      @media (max-width: $screen-xs-max) {
        width: 80%;
      }
    }

    .teaser__heading {
      margin-bottom: 15px;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 10px;
      }
    }

    .teaser__text {
      margin-bottom: 20px;
      line-height: 22px;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 10px;
      }
    }

    .teaser__link {
      margin-bottom: 0;
    }
  }

  /**
   *  Left wide
   */
  &.theme--left-wide {
    @include clearfix;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
      border: 0;
    }

    .teaser_placeholder {
      padding-top: 56.25%;
      position: relative;
      background-color: $color-one;
      overflow: hidden;

      @media (max-width: $screen-xs-max) {
        padding-top: 100%;
      }
    }

    .du-ratio-1x1 {
      display: none;
    }

    .teaser_images {
      float: left;
      width: 50%;

      @media (max-width: $screen-xs-max) {
        width: 20%;
      }
    }

    .teaser__wrapper {
      position: relative;
      float: left;
      width: 50%;

      @media (max-width: $screen-xs-max) {
        width: 80%;
      }
    }

    .teaser__content {
      width: 100%;
      padding-left: 20px;
    }

    .teaser__heading {
      margin-bottom: 15px;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 10px;
      }
    }

    .teaser__text {
      margin-bottom: 20px;
      line-height: 22px;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 10px;
      }
    }

    .teaser__link {
      margin-bottom: 0;

      @media (max-width: $screen-xs-max) {
        font-size: 16px;
      }
    }

    @media (max-width: $screen-xs-max) {
      @include clearfix;
      padding-bottom: 5px;

      .du-ratio-1x1 {
        display: block;
      }

      .du-ratio-16x9 {
        display: none;
      }

      .teaser_images {
        float: left;
        width: 20%;
      }

      .teaser__wrapper {
        float: left;
        padding-left: 20px;
        padding-top: 0;
        padding-right: 0;
        width: 80%;
      }

      .teaser__content {
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        padding-left: 0;
      }

      .teaser__heading {
        margin-bottom: 10px;
      }

      .teaser__text {
        line-height: 22px;

        margin-bottom: 10px;
      }

      .teaser__link {
        margin-bottom: 0;
      }
    }
  }
}

.component-teaserbackgroundimage {
  position: relative;
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    margin: 15px 0;
  }

  h1, h2, h3, h4, h5, h6 {
    display: block;
    margin-bottom: 0;
    position: relative;

    @mixin heading-color($color-name, $color-value, $tile-text) {
      &.color-#{$color-name}{
        color: $color-value;
        &.background-tile {
          line-height: 1.4;
          font-weight: $font-weight-base;
          span {
            background: $color-value;
            font-family: $headings-font-family-light;
            color: $tile-text;
            box-decoration-break: clone;
            padding: 8px 10px 0;
          }
        }
      }
    }

    @include heading-color('black', $black, $white);
    @include heading-color('grey', $gray, $white);
    @include heading-color('grey-light', $gray-light, $white);
    @include heading-color('white', $white, $color-one);
    @include heading-color('one', $color-one, $white);
    @include heading-color('two', $color-two, $white);
    @include heading-color('three', $color-three, $white);
    @include heading-color('four', $color-four, $white);
    @include heading-color('five', $color-five, $white);
    @include heading-color('six', $color-six, $white);

    &.color-one {
      &.background-tile {
        span {
          background: linear-gradient(45deg, $color-one 60%, $color-seven 100%);
        }
      }
    }


  }

  &.call-to-action {
    border-radius: 5px;
    box-shadow: $box-shadow;
    transition: box-shadow 100ms ease;
    &:hover {
      box-shadow: $box-shadow-hover;
    }
  }
  &.bg-grey {
    background-color: $gray-darker;
  }
  &.bg-one {
    background-color: $color-one;
  }

  .image {
  }

  .content {
    display: block;
    position: absolute;
    right: 20px;
    bottom: 15px;
    left: 20px;
    z-index: 3;
    &.top {
      top: 15px;
    }
    &.bottom {
      bottom: 15px;
    }
    button {
      margin-top: 10px;
    }
  }



  .pattern {
    display: block;
    width: 100%;
    padding-top: 75%;
    background-size: cover;

    @mixin background-color($color-name, $color-value) {
      &.bg-#{$color-name}{
        background: $color-value;
      }
    }
    @include background-color('black', $black);
    @include background-color('white', $white);
    @include background-color('grey', $gray-dark);
    @include background-color('grey-light', $gray-light);
    @include background-color('grey-dark', $gray-darker);
    @include background-color('one', $color-one);
    @include background-color('two', $color-two);
    @include background-color('three', $color-three);
    @include background-color('four', $color-four);
    @include background-color('five', $color-five);
    @include background-color('six', $color-six);

    &:after {
      content: "";
      background: url('pattern.svg') no-repeat;
      background-size: 200%;
      background-position: center -200px;
      opacity: 0.3;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
    }
  }

  .wrapper {
    padding: $margin * 2;
    text-align: center;
    position: relative;
    justify-content: center;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .text {
    line-height: 1.2;
    color: $white;

    @include font-size((
      null : 18px,
      small : 20px,
      medium: 22px,
      large : 24px,
    ));
  }

  .component-button.align-left {
    text-align: center;
  }

  .image-full {

    @media screen and (min-width: $screen-sm-min) {
      .wrapper {
        text-align: left;
        padding-left: 50%;
      }
      .heading {
        @include font-size((
          null : 38px,
          small : 42px,
          medium: 46px,
          large : 50px,
        ));
        line-height: 1.06;
      }
      .component-button.align-left {
        text-align: left;
      }
    }
  }

  &.bkg-blend {
    .image{
      opacity: 0.3;

      @supports (mix-blend-mode: multiply) {
        opacity: 1;
        background-blend-mode: luminosity;
        mix-blend-mode: multiply;
        filter: grayscale(100%);
      }
    }
  }

  &.heading_on_tint {
    background: $black;

    .coh-resp-img-ratio-wrapper {
      opacity: 0.5;
      transform: scale(1);
      transition: transform 0.3s ease;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: center;

      .heading {
        span {
          background: none;
          font-size: 24px;
          font-family: $font-family-base;
          font-weight: $font-weight-semibold;
          text-transform: none;
          letter-spacing: 0;
        }
      }
    }

    &:hover {
      .coh-resp-img-ratio-wrapper {
        transform: scale(1.05);
      }
    }
  }


}

@media screen and (min-width: $screen-sm-min) {
  .column-vertical-stretch .component-teaserbackgroundimage {
    height: 100%;

    .image-wide, .image-square {
      height: 100%;
      padding-top: 0;

      .wrapper {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.wpb_text_column {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
  }

  a {

  }

  ul,
  ol {
    padding-left: 20px;
  }

  ul {
    padding-left: 0;

    li {
      padding-left: 20px;
      position: relative;
      list-style-type: none;
      font-family: $font-family-demi;
      margin-bottom: 10px;

      &:before {
        content: ' ';
        display: inline-block;
        width: 5px;
        height: 5px;
        background: $text-color;
        border-radius: 50%;

        position: absolute;
        top: 7.5px;
        left: 2px;
      }
    }
  }

  img {
    max-width: 80%;
    height: auto;
  }

  @media (min-width: $screen-xs-max) {
    img {
      max-width: 100%;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}


.component-uspslider {
  // Most styles are in post slider component

  .title {
    text-align: center;
  }

}
.component-video {
  overflow: hidden;
  position: relative;
  margin-bottom: $margin;
  cursor: pointer;

  .icon {
    font-size: 50px;
    text-align: center;
    line-height: 60px;
    height: 60px;
    width: 60px;
    color: $color-one;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all $transitionTime $easeCurve;
    //background: rgba($white, 0.7);
    border-radius: 50%;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    pointer-events: none;
    &:before {
      vertical-align: baseline;
    }
    &:hover {
      transform: scale(1.15);
    }
  }
}

.fancybox-skin {
  border-radius: 0;
}

.youtube-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  background-size: cover;
  background-position: center;
}

.youtube-player {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &:hover {
    + .icon {
      //background: rgba($white, 1);
      transform: scale(1.15);
    }
  }

  > div {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba($black, 0.15);
  }
}

.youtube-player iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.component-videoslider {
  // Height according to ratios
  $_24_9: ((100vw/24) * 9);
  $_16_9: ((100vw/16) * 9);
  $_4_3: ((100vw/4) * 3);
  $_1_1: 100vw;

  // Chosen ratios
  $wide_ratio: $_24_9;
  $narrow_ratio: $_1_1;

  //custom screensizes
  $screen-desktop: 993px;
  $screen-mobile: 460px;

  // @media(min-width: $screen-md-min) {
  //     max-height: 540px;  // 24:9 up to 1440px wide, then crop
  // }

  .tin-video-slider--slide-video {
    background-repeat: no-repeat;
    background-size: cover;
  }

  p{
    font-size: 19px;
    line-height: 27px;
  }

  &:before {
    content: '';
    position: absolute;
    background-color: white;
    height: 100%;
    width: 100vw;
    min-width: 100px; // IE
    left: 50%;
    transform: translateX(-50%);
  }

  .tin-video-slider {
    overflow: visible !important;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: -5px;
    padding-left: 0;

    &--slide {
      position: relative;
      background-position: center center;
      background-size: cover;

      &-video {
        @media(min-width: $screen-desktop) {
          height: $wide_ratio !important; // 24:9
        }

        @media(max-width: $screen-desktop) {
          height: 80vh;
          width: 100%;
          background-position: center;
          background-repeat: no-repeat;
        }

        video {
          position: relative;
          height: auto;

          @media(min-width: $screen-sm-min) {
            width: 100vw;
          }

          @media(max-width: $screen-mobile) {
            height: 80vh;
            min-width: 100%;
            object-fit: cover;
          }

          @media(min-width: $screen-mobile) and (max-width: $screen-sm-min) {
            height: 80vh;
            min-width: 100%;
            object-fit: cover;
          }
        }
      }

      &--content {
        padding: 20px;
        height: 100%;
      }

      .text-wrapper{
        margin-bottom: 20px;
      }

      @media(min-width: $screen-md-min) {
        .row {
          margin-left: -35px;
          margin-right: -35px;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .container {
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
          height: 100%;
        }

        .component-column {
          padding-left: 15px;
          padding-right: 15px;

          &>div {
            padding: 30px;
            border-radius: 5px;
            position: relative;
            z-index: 3;

            .component-button {
              margin-bottom: 0;
            }

            p:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      @media(max-width: $screen-desktop) {
        .row {
          width: 90vw;
          text-align: center;
        }

        .container {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
        }
      }

      &.transparent-bkg {
        .tin-video-slider--slide-video-desktop {
          position: relative;

          @media(min-width: $screen-desktop) {
            &:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: radial-gradient(circle at bottom right, transparent 30%, $black)
            }
          }
        }

        .component-column>div {
          background-color: transparent;
        }

        .tin-video-slider--slide-video-phone {
          position: relative;

          @media(max-width: $screen-desktop) {
            &:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(transparent 30%, $black);
            }
          }
        }

        .container {
          @media(min-width: $screen-md-min) {
            top: 50%;
            transform: translate(-50%, -50%);

            .component-column>div {
              background-color: transparent;


            }
          }
        }
      }
    }
  }


  .tin-slide-dots {
    padding-left: 0;

    @media(min-width: $screen-sm-min) {
      bottom: auto;
      top: calc(((100vw/24) * 9) - 25px - 10px); // 24:9
    }

    @media(max-width: $screen-xs-max) {
      top: calc(100vw - 22px - 10px); // 1:1
    }

    li {
      background-color: $color-one;
      border-color: transparent;
      transform: scale(.8);

      &.on {
        border-color: $color-one;
        background-color: $color-one;
        transform: scale(1);
      }

      &:hover {
        background-color: $color-one-hover;
      }
    }
  }

  .tin-slide-prev,
  .tin-slide-next {
    &:before {
      width: auto;
      height: auto;
      border: 0;
      font-family: icomoon;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      color: #e52329;
      line-height: 30px;
      background: rgba(0, 0, 0, .75);
      padding: 5px;
      display: block;
      opacity: 0.5;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:after {
      display: none;
    }
  }

  .tin-slide-prev {
    &:before {
      content: $icon-arrow-left;
    }


  }

  .tin-slide-next {
    &:before {
      content: $icon-arrow-right;
    }
  }
}

.component-counterslider {
  overflow: hidden;

  .title {
    text-align: center;
  }

  .container {
    width: auto;
  }
}
.padding-bottom--xsmall {
  @include get-variable-attribute('padding-bottom', (
    null: 20px,
    small: 25px,
    medium: 35px,
    large: 45px)
  );
}

.padding-bottom--small {
  @include get-variable-attribute('padding-bottom', (
    null: 25px,
    small: 30px,
    medium: 40px,
    large: 50px)
  );
}

.padding-bottom--medium {
  @include get-variable-attribute('padding-bottom', (
    null: 30px,
    small: 40px,
    medium: 50px,
    large: 60px
  ));
}

.padding-bottom--large {
  @include get-variable-attribute('padding-bottom', (
    null: 50px,
    small: 60px,
    medium: 70px,
    large: 80px
  ));
}

.padding-bottom--xlarge {
  @include get-variable-attribute('padding-bottom', (
    null: 55px,
    small: 80px,
    medium: 175px,
    large: 180px
  ));
}

.padding-top--xsmall {
  @include get-variable-attribute('padding-top', (
    null: 20px,
    small: 25px,
    medium: 35px,
    large: 45px)
  );
}

.padding-top--small {
  @include get-variable-attribute('padding-top', (
    null: 25px,
    small: 30px,
    medium: 40px,
    large: 50px)
  );
}

.padding-top--medium {
  @include get-variable-attribute('padding-top', (
    null: 30px,
    small: 40px,
    medium: 50px,
    large: 60px
  ));
}

.padding-top--large {
  @include get-variable-attribute('padding-top', (
    null: 50px,
    small: 60px,
    medium: 70px,
    large: 80px
  ));
}

.padding-top--xlarge {
  @include get-variable-attribute('padding-top', (
    null: 55px,
    small: 65px,
    medium: 75px,
    large: 90px
  ));
}

.margin-bottom--none {
  @include get-variable-attribute('margin-bottom', (
    null: 0px,
    small: 0px,
    medium: 0px,
    large: 0px)
  );
}

.margin-bottom--xsmall {
  @include get-variable-attribute('margin-bottom', (
    null: 5px,
    small: 5px,
    medium: 10px,
    large: 15px)
  );
}

.margin-bottom--small {
  @include get-variable-attribute('margin-bottom', (
    null: 10px,
    small: 10px,
    medium: 15px,
    large: 20px)
  );
}

.margin-bottom--medium {
  @include get-variable-attribute('margin-bottom', (
    null: 15px,
    small: 20px,
    medium: 25px,
    large: 30px
  ));
}

.margin-bottom--large {
  @include get-variable-attribute('margin-bottom', (
    null: 35px,
    small: 40px,
    medium: 45px,
    large: 50px
  ));
}

.margin-bottom--xlarge {
  @include get-variable-attribute('margin-bottom', (
    null: 40px,
    small: 45px,
    medium: 50px,
    large: 60px
  ));
}

.margin-top--none {
  @include get-variable-attribute('margin-top', (
    null: 0px,
    small: 0px,
    medium: 0px,
    large: 0px)
  );
}

.margin-top--xsmall {
  @include get-variable-attribute('margin-top', (
    null: 5px,
    small: 5px,
    medium: 10px,
    large: 15px)
  );
}

.margin-top--small {
  @include get-variable-attribute('margin-top', (
    null: 10px,
    small: 10px,
    medium: 15px,
    large: 20px)
  );
}

.margin-top--medium {
  @include get-variable-attribute('margin-top', (
    null: 15px,
    small: 20px,
    medium: 25px,
    large: 30px
  ));
}

.margin-top--large {
  @include get-variable-attribute('margin-top', (
    null: 35px,
    small: 40px,
    medium: 45px,
    large: 50px
  ));
}

.margin-top--xlarge {
  @include get-variable-attribute('margin-top', (
    null: 40px,
    small: 45px,
    medium: 50px,
    large: 60px
  ));
}

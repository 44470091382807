.btn,
#mc-embedded-subscribe {
  color: $white;
  background-color: $color-one;
  display: inline-block;
  padding: 12px 20px;
  text-align: center;
  position: relative;
  border-radius: 5px;
  transition: background-color 0.2s;
  font-weight: 500;
  border: none;
  -webkit-appearance: none;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  line-height: 1;
  outline: 0;


  &:hover, &:focus {
    backface-visibility: hidden;
    color: $white;
  }
  &:active {
    background-color: $brand-primary-hover;
  }

  &.btn-small {
    padding: 8px 18px;
  }

  &.fancybox-iframe {
    height: auto;
    width: auto;
  }
}

.call-to-action {
  .btn {
    max-width: 275px;
    white-space: normal;
    text-align: left;
    border-left: 8px solid $white;
    padding: 15px 20px;
    &.btn-color-white {
      border-color: $color-one;
    }
  }
}

@mixin button-bg-color($color-name, $color-value, $text-color) {
  .btn-color-#{$color-name}{
    background-color: $color-value;
    color: $text-color;
    &:hover, &:focus, &:active {
      box-shadow: $box-shadow-hover;
    }
  }
}

.btn-color-four {
  &:hover, &:focus, &:active {
    color: $black;
  }
}

.btn-color-white {
  &:hover, &:focus, &:active {
    background-color: $white !important;
    color: $black;
  }
}

@include button-bg-color('black', $black, $white);
@include button-bg-color('white', $white, $black);
@include button-bg-color('grey', $gray-dark, $white);
@include button-bg-color('grey-light', $gray-light, $white);
@include button-bg-color('grey-dark', $gray-dark, $white);
@include button-bg-color('one', $color-one, $white);
@include button-bg-color('two', $color-two, $white);
@include button-bg-color('three', $color-three, $white);
@include button-bg-color('four', $color-four, $text-color);
@include button-bg-color('five', $color-five, $white);
@include button-bg-color('six', $color-six, $white);
@include button-bg-color('eight', $color-eight, $white);

.btn-color-one {
  background: $red-gradient;
}

// Link Button
.btn-link {
  font-size: $font-size-big;
  display: inline-block;
  //font-family: $headings-font-family;
  color: $color-brand;
  position: relative;
  //text-transform: uppercase;
  //@extend %link-underline;
  &:hover {
    color: darken($link-color, $tint);
    text-decoration: underline;
  }
}


@mixin link-button-color($color-name, $color-value, $color-hover) {
  .btn-link.color-#{$color-name}{
    color: $color-value;
    &:hover, &:focus, &:active {
      color: $color-hover;
    }
  }
}

@include link-button-color('black', $black, $gray-darker);
@include link-button-color('white', $white, $gray-light);
@include link-button-color('grey', $gray-dark, $gray-dark);
@include link-button-color('grey-lighter', $gray-lighter, $gray);
@include link-button-color('grey-darker', $gray-darker, $black);
@include link-button-color('one', $color-one, $color-one-hover);
@include link-button-color('two', $color-two, $color-two-hover);
@include link-button-color('three', $color-three, $color-three-hover);
@include link-button-color('four', $color-four, $color-four-hover);
@include link-button-color('five', $color-five, $color-five-hover);
@include link-button-color('six', $color-six, $color-six-hover);




.btn-icon {
  .btn {
    position: relative;
    padding-right: 54px;
    &.btn-small {
      padding-right: 52px;
      .icon {
        right: 18px;
      }
    }
  }

  .icon {
    display: block;
    position: absolute;
    right: 20px;
    font-size: 24px;
    height: 24px;
    line-height: 1em;
    width: 24px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    top: 0;
    bottom: 0;
    color: inherit;
    &:before {
      vertical-align: inherit;
    }
  }
}

@keyframes pop-in {
    0% {
        opacity: 1;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}
@keyframes body-menu-height-mobile {
    0% {
        padding-top: 0px;
    }
    100% {
      padding-top: 80px;
    }
}
@keyframes body-menu-height-desktop {
    0% {
        padding-top: 0px;
    }
    100% {
      padding-top: 100px;
    }
}
@keyframes sub-menu-height {
    0% {
        height: 0;
    }
    100% {
        height: 40px;
    }
}
@keyframes filter-menu-height {
    0% {
        height: 0;
    }
    100% {
        height: 60px;
    }
}

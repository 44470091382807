.navigation, .widget_nav_menu {
  ul {
    @extend %clearfix;
    display: block;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    display: block;
  }

  a {
    display: block;
  }
}

.widget_nav_menu {
  li {
    float: inherit;
  }

  a {
    display: inline-block;
  }
}

html {
  height: 100%;
}

body {
  color: $text-color;
  background: $gray-lighter;
  animation: body-menu-height-mobile .6s ease-out forwards;
  display: flex;
  flex-direction: column;

  .loaded-as-iframe & {
    animation: none !important;
    padding-top: 0;
  }
  @include media-query(large) {
    animation: body-menu-height-desktop .6s ease-out forwards;
    &:not(.home){
      animation: none;
      padding-top: 100px;
      &.has-sub-menu {
        padding-top: 140px;

        &.single-references{
          padding-top: 100px;
        }
      }
    }
    .loaded-as-iframe & {
      animation: none !important;
      padding-top: 0;
    }
  }
}
.wrap {
  height: 100%;
  flex: 1;
  align-self: stretch;
  .content {
    main {
      width: 100%;
    }
  }
}

select {
  height: 30px;
  background: #fff;
  background-size: 16px;
  outline: 0;
  display: inline-block;
  line-height: 1;
  padding: 0 20px;
  padding-right: 40px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: underline;
  border: 0;
}
input[type="text"], textarea {
  border: 0;
  padding: 6px 15px;
  border-radius: 5px;
  outline: 0;
  & + submit {
    margin-left: 5px;
  }
}

.component-contentbox {
  &.news {
    a {
      position: relative;
      margin-top: 5px;
      text-transform: uppercase;
      text-decoration: none;
      @extend %link-underline;
    }
  }
}

i.icon {
  width: auto;
  height: auto;
  line-height: 1;
  &:before {
    vertical-align: middle;
  }
}

%link-underline {
  position: relative;
  padding-bottom: 5px;
  font-family: $headings-font-family;
  letter-spacing: 0.05em;
  font-weight: 400 !important;
  i {
    margin-left: 10px;
    font-size: 30px;
    vertical-align: middle;
    &:before {
      vertical-align: baseline !important;
    }
  }
  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #e52329;
  }
}

.padding-left-medium {
  @include media-query(medium) {
    padding-left: 15px;
  }
}

.color-black {
  color: $black;
}
.color-grey {
  color: $gray;
}
.color-grey-light {
  color: $gray-light;
}
.color-white {
  color: $white;
}
.color-brand {
  color: $color-brand;
}
.color-one {
  color: $color-one;
}
.color-two {
  color: $color-two;
}
.color-three {
  color: $color-three;
}
.color-three-dark {
  color: $color-three-dark;
}
.color-four {
  color: $color-four;
}
.color-four-dark {
  color: $color-four-dark;
}
.color-five {
  color: $color-five;
}
.color-five-dark {
  color: $color-five-dark;
}
.color-six {
  color: $color-six;
}


html.loaded-as-iframe {
  .wt-cli-cookie-bar-container,
  .imbox-frame,
  #wpadminbar {
    display: none !important;
  }
}

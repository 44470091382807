// Fixes the frontend-editor
.vc_editor [class^="col-"], .vc_editor [class*=" col-"] {
  width: 100% !important;
}

.vc_col-sm-6 {
  @include make-xs-column(6);
  @include make-sm-column(6);
}

.vc_col-sm-2 {
  @include make-xs-column(2);
  @include make-sm-column(2);
}

.vc_col-sm-3 {
  @include make-xs-column(3);
  @include make-sm-column(3);
}

.vc_col-sm-4 {
  @include make-xs-column(4);
  @include make-sm-column(4);
}

.vc_col-sm-5 {
  @include make-xs-column(5);
  @include make-sm-column(5);
}

.vc_col-sm-7 {
  @include make-xs-column(7);
  @include make-sm-column(7);
}

.vc_col-sm-8 {
  @include make-xs-column(8);
  @include make-sm-column(8);
}

.vc_col-sm-9 {
  @include make-xs-column(9);
  @include make-sm-column(9);
}

.vc_col-sm-1 {
  @include make-xs-column(1);
  @include make-sm-column(1);
}

.vc_col-sm-10 {
  @include make-xs-column(10);
  @include make-sm-column(10);
}

.vc_col-sm-11 {
  @include make-xs-column(11);
  @include make-sm-column(11);
}

.vc_col-sm-12 {
  @include make-xs-column(12);
  @include make-sm-column(12);
}

// Responsive Google Map
.wpb_map_wraper iframe {
  width: 100% !important;
}